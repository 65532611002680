import React from "react";
/** composants **/
import { Navbar } from '../Navbar';
import Footer from '../components/Footer';
import BlocCta from '../components/BlocCta';
import { Container } from "../annuaire/Container";
import Faq from '../components/Faq';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Gradient } from "../annuaire/Gradient";
//img & icon
import { CheckIcon } from "@heroicons/react/20/solid";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import AchatAvantage2 from "../img/illu-pma.webp";

const faqs = [
    {
        question: 'Quelles sont les avantages juridiques d\'une reconnaissance conjointe anticipée ?',
        answer:
            'Clotere vous permet de regrouper tous vos échanges, documents et événements avec toutes les parties prenantes de votre transaction immobilière.Ainsi, en vous proposant des rappels automatiques, des fixations de rendez-vous en ligne…etc, vous signez plus rapidement l\'acte de vente !',
    },
    {
        question: 'Est-ce que Clotere est un notaire ?',
        answer:
            'Non ! Clotere est une plateforme de mise en relation entre des utilisateurs et des notaires.',
    },
    {
        question: 'Est-ce que Clotere est un système sécurisé ?',
        answer:
            'La confidentialité et la sécurité de vos données sont toujours protégées lorsque vous utilisez Clotere. Nous utilisons la technologie SSL ; Les serveurs Clotere sont situés dans des centres de données en France conformes aux normes ISO 27001 ; Aucune de vos données personnelles n’est visible ni stockée par l\'un de nos partenaires ou entreprises sous-traitantes, ou utilisée à des fins publicitaires ; Conformément à la loi RGPR, les utilisateurs peuvent corriger, bloquer ou supprimer leurs données personnelles à tout moment',
    },
    // More questions...
]

function Pma(args) {
    return (
        <>
            <Helmet>
                <title>Trouver un notaire pour votre reconnaissance conjointe anticipée (PMA)</title>
                <meta name="robots" content="index,follow" />
                <meta name="googlebot" content="index,follow" />
                <meta name='description' content='Clotere.fr vous aide à trouver votre notaire pour votre reconnaissance conjointe anticipée dans le cadre d/une PMA.' />
                <meta property="og:url" content="https://clotere.fr/notaire-acheteur" />
                <meta property="og:site_name" content="Clotere" />
                <meta property="og:image" content="https://clotere.fr/facebook-share-webp" />
                <meta property="og:type" content="website" />
            </Helmet>




            {/**header 2 */}
            <div className="relative rounded-4xl ">
                <Navbar user={args.user} />
                <Gradient className="absolute inset-2 bottom-0 rounded-4xl ring-1 ring-inset ring-black/5" />
                <Container className="relative ">

                    <header class="flex flex-row flex-wrap justify-center py-10">
                        <div className="w-full sm:basis-1/2 p-4">
                            <h1 className="font-medium text-transparent sm:!text-[45px] tracking-[0] leading-10 mb-3" style={{ lineHeight: "50px" }}>
                                <span className=" text-black">Trouvez un notaire pour sécuriser  <span className="font-bold mt-2 text-indigo-800">votre reconnaissance d’enfant (RCA)</span>. </span>
                            </h1>

                            <div className="flex flex-column justify-start gap-3">
                                <div className="flex items-center gap-2 relative flex-[0_0_auto]">
                                    <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                    <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                        <p className="m-0 text-lg"><b className="text-indigo-800">Simple</b> : quelques informations suffisent pour démarrer votre projet.</p>
                                    </div>
                                </div>
                                <div className="flex items-center  gap-2 relative flex-[0_0_auto]">
                                    <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                    <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                        <p className="m-0 text-lg"><b className="text-indigo-800">Sûr</b> : déposez dans votre espace sécurisé les documents nécessaires.</p>
                                    </div>
                                </div>
                                <div className="flex items-center  gap-2 relative flex-[0_0_auto]">
                                    <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                    <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                        <p className="m-0 text-lg"><b className="text-indigo-800">Rapide</b> : votre notaire prends en charge votre dossier rapidement.</p>
                                    </div>
                                </div>
                                <div className="flex items-center  gap-2 relative flex-[0_0_auto]">
                                    <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                    <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                        <p className="m-0 text-lg"><b className="text-indigo-800">Suivi</b> : un conseiller dédié disponible pour vous accompagner de A à Z.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <Link to="/onboard/pma"
                                    type="button"
                                    className="box-content mt-3 no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Débuter mon dossier
                                    <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                                </Link>
                            </div>
                        </div>
                        <div className="w-full sm:basis-1/2 p-0 lg:p-5 hidden sm:flex bg-yellow-500 overflow-hidden rounded-2xl">
                            <img className="object-cover" src="https://framerusercontent.com/images/DlQrHBD7UTZEiVawVV67NMIsw.jpeg" alt="Vos démarches chez le notaire pour votre PMA" />

                        </div>
                    </header>
                </Container>
            </div>

            {/** section ccm **/}
            <section id='section1' className="mt-20 mb-20">
                <Container>
                    <div class="container mx-auto px-[15px] h-full">
                        <div class="flex flex-col justify-between items-start gap-[30px]">
                            <h3 class="text-black leading-[48px] text-[40px] lg:leading-[50px] lg:text-[45px] font-bold ">Votre <span className="text-indigo-800">reconnaissance conjointe anticipée (RCA</span>) avec Clotere, comment ça marche ?</h3>

                            <div class="grid lg:grid-cols-4 w-full gap-[10px] ">
                                <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">
                                    <div class="flex flex-col justify-end items-start gap-[10px] max-w-[253px]">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                            <path fill-rule="evenodd" d="M4.848 2.771A49.144 49.144 0 0 1 12 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97-1.94.284-3.916.455-5.922.505a.39.39 0 0 0-.266.112L8.78 21.53A.75.75 0 0 1 7.5 21v-3.955a48.842 48.842 0 0 1-2.652-.316c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97Z" clip-rule="evenodd" />
                                        </svg>
                                        <h3 class="text-black font-bold text-[22px] leading-[22px]">Répondez à quelques questions</h3>
                                        <p>Nous avons besoin de certaines informations pour préparer votre dossier.</p>
                                    </div>
                                </div>
                                <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">
                                    <div class="flex flex-col justify-end items-start gap-[10px] max-w-[253px]">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                            <path fill-rule="evenodd" d="M9 1.5H5.625c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5Zm6.61 10.936a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 14.47a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                                            <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                                        </svg>
                                        <h3 class="text-black font-bold text-[22px] leading-[22px]">Ajoutez vos documents</h3>
                                        <p>Ajoutez dans votre espace sécurisé les documents nécessaires à votre dossier chez le notaire.</p>
                                    </div>
                                </div>
                                <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">
                                    <div class="flex flex-col justify-end items-start gap-[10px] max-w-[253px]">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                            <path fill-rule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clip-rule="evenodd" />
                                        </svg>
                                        <h3 class="text-black font-bold text-[22px] leading-[22px]">Choisissez votre notaire</h3>
                                        <p>A vous de choisir votre notaire pour gérer. 100% à distance ou à proximité de chez vous c’est vous qui choisissez.</p>
                                    </div>
                                </div>
                                <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">

                                    <div class="flex flex-col justify-end items-start gap-[10px] max-w-[253px]">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                            <path fill-rule="evenodd" d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z" clip-rule="evenodd" />
                                        </svg>
                                        <h3 class="text-black font-bold text-[22px] leading-[22px]">Obtenez votre reconnaissance conjointe d'enfant</h3>
                                        <p>Faites vos rendez-vous en visio ou présentiel, et obtenez directement notre plateforme vos documents notariés.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col gap-3 mt-5">
                            <div className="flex -space-x-2 overflow-hidden">
                                <img
                                    alt="trouver mon notaire acheteur"
                                    src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                    className="inline-block size-10 rounded-full ring-2 ring-white"
                                />
                                <img
                                    alt="notaire pour votre achat immobilier"
                                    src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                    className="inline-block size-10 rounded-full ring-2 ring-white"
                                />
                                <img
                                    alt="trouver mon notaire"
                                    src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                                    className="inline-block size-10 rounded-full ring-2 ring-white"
                                />
                                <img
                                    alt="trouver un notaire"
                                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                    className="inline-block size-10 rounded-full ring-2 ring-white"
                                />
                            </div>
                            <div className="">
                                <p className="text-lg">Votre conseiller dédié Clotere se fera un plaisir de répondre à toutes vos questions, tout au long de votre démarche </p>
                            </div>

                        </div>
                        <Link to="/onboard/pma"
                            type="button"
                            className="box-content no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Débuter mon dossier
                            <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                        </Link>
                    </div>
                </Container>
            </section>

            {/** section SEO */}
            <section class=" mt-4 mb-4 flex flex-row flex-wrap justify-center items-center container">
                <div className="w-full sm:basis-1/2  p-4">
                    <h2 className="font-bold">Tout savoir sur <span className="text-indigo-800">la reconnaissance conjointe d'enfant</span></h2>
                    <p>
                        Lorsqu’un couple, a recours à une Procréation Médicalement Assistée (PMA), il est essentiel de formaliser la filiation et la reconnaissance conjointe auprès d’un notaire. Cette étape garantit des droits égaux pour les deux membres du couple vis-à-vis de l’enfant.
                        <br /><br />Depuis la loi de bioéthique de 2021, la reconnaissance conjointe est possible avant même la naissance de l’enfant. <span className="text-indigo-800 font-bold">Elle permet de protéger juridiquement la famille et d’assurer une égalité de droits entre les deux parents</span>.
                        <br /><br />Clotere vous permet de créer votre dossier de reconnaissance conjointe d'enfant en ligne, et d'échanger avec un notaire pour vous accompagner dans cette démarche.

                    </p>
                    <Link to="/onboard/pma"
                        type="button"
                        className="no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Débuter ma procédure
                        <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                    </Link>
                </div>
                <div className="w-full sm:basis-1/2  p-4 ">
                    <img alt="trouvez un notaire pour votre achat immobilier" src={AchatAvantage2} className="rounded overflow-hidden w-4/5 mx-auto" />
                </div>


            </section>

            {/** section FAQ */}
            <section class=" mt-4 flex flex-row flex-wrap justify-center items-center bg-slate-100">
                <div className="py-24 sm:py-20 ">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="mx-auto text-center">
                            <h2 className="text-3xl font-bold tracking-tight leading-10 text-gray-900 sm:text-4xl">
                                <span className="font-bold text-indigo-800">Reconnaissance conjointe d'enfant (PMA)</span>
                                : 5 informations importantes</h2>

                        </div>
                        <div className="mt-10 lg:col-span-7 lg:mt-0 sm:max-w-[60%] mx-auto">
                            <dl className="space-y-10">


                                <div className="bg-white my-4 rounded-lg p-4">
                                    <dt className="text-lg font-semibold leading-7 ">
                                        Quelles sont les points d'attention pendant la démarche ?
                                    </dt>
                                    <dd className="mt-2 text-md leading-7 text-gray-600">
                                        Le processus de PMA est récent en France, Clotere.fr vous accompagne dans cette démarche et vous permet d'obtenir vos documents conformes et nécessaires pour la filiation de votre enfant.
                                        <br />
                                        <ul>
                                            <li>• <span className="text-indigo-800 font-bold">Deux actes distincts</span> : La reconnaissance conjointe anticipée et le consentement à la PMA doivent être rédigés sur des documents séparés. Ils ne doivent pas figurer sur le même papier.</li>
                                            <li>• <span className="text-indigo-800 font-bold">Aucun détail superflu</span> : Les documents ne doivent pas mentionner des informations comme la mère porteuse, le pays de la PMA ou la méthode utilisée. En effet, si ces détails sont inscrits et que l’un de ces paramètres change, votre RCA pourrait devenir invalide.</li>
                                            <li>• <span className="text-indigo-800 font-bold">Numéro CRPCEN </span> :  Le numéro CRPCEN de l’étude notariale figure bien sur les documents.</li>
                                        </ul>
                                    </dd>
                                </div>

                                <div className="bg-white my-4 rounded-lg p-4">
                                    <dt className="text-lg font-semibold leading-7 ">
                                        Doit-on être pacsé ou marié pour une PMA ?
                                    </dt>
                                    <dd className="mt-2 text-md leading-7 text-gray-600">
                                        Contrairement à certaines idées reçues, il n’est pas nécessaire d’être mariées ou pacsées pour établir ces documents. La RCA et le consentement à la PMA sont accessibles à tous les couples, quelle que soit leur situation matrimoniale.
                                    </dd>
                                </div>

                                <div className="bg-white my-4 rounded-lg p-4">
                                    <dt className="text-lg font-semibold leading-7 ">
                                        Quand aller chez le notaire pour ma démarche PMA ?
                                    </dt>
                                    <dd className="mt-2 text-md leading-7 text-gray-600">
                                        Avant de commencer votre PMA, il est obligatoire de vous rendre chez un notaire pour signer une reconnaissance conjointe anticipée (RCA). Ce document permet d’établir la filiation de la mère sociale dès la naissance de l’enfant. Sans cette démarche, la reconnaissance légale de la mère sociale ne serait pas automatique.
                                    </dd>
                                </div>
                                <div className="bg-white my-4 rounded-lg p-4">
                                    <dt className="text-lg font-semibold leading-7 ">
                                        Quels sont les avantages juridiques d'une RCA ?
                                    </dt>
                                    <dd className="mt-2 text-md leading-7 text-gray-600">
                                        La reconnaissance conjointe d’enfant chez un notaire garantit une <span className="text-indigo-800 font-bold">filiation claire et incontestable</span>, assurant aux deux parents une reconnaissance immédiate et une protection juridique contre d’éventuelles contestations.
                                        <br /><br />
                                        Elle permet également <span className="text-indigo-800 font-bold">d’établir des droits parentaux égaux</span>, notamment en matière d’autorité parentale et de succession, ce qui est particulièrement important pour les couples non mariés.
                                        <br /><br />
                                        En cas de séparation, cet acte protège les droits de chacun et sert de base pour organiser la garde et la résidence de l’enfant, prévenant ainsi d’éventuels conflits.
                                        <br /><br />
                                        Cette reconnaissance est essentielle pour les enfants nés hors mariage, évitant tout risque de non-reconnaissance par un parent et facilitant les démarches administratives, comme l’accès aux droits sociaux, les voyages ou les changements d’état civil. 🚀
                                    </dd>
                                </div>
                            </dl>
                        </div>


                    </div>

                </div>

            </section>

            {/** section CTA */}
            <div className="bg-indigo-100">
                <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
                    <div>
                        <h2 className="max-w-2xl text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                            Vous souhaitez plus d'informations ?
                        </h2>
                        <p>Nos experts se feront un plaisir de répondre à toutes vos questions, n'hésitez pas à prendre rendez-vous</p>
                    </div>
                    <div className="mt-10 flex flex-col items-center gap-x-6 lg:mt-0 lg:shrink-0">
                        <a
                            href="#"
                            className="btn-indigo no-underline p-6"
                        >
                            Prendre un rendez-vous
                        </a>
                        <a href="#" className="text-sm/6 font-semibold text-gray-900 mt-4">
                            Débuter mon dossier aujourd'hui <span aria-hidden="true">→</span>
                        </a>
                    </div>
                </div>
            </div>


            <Footer />
        </>
    );
}

export default Pma;