import React from "react";
import { Container } from "./Container";
import Pourquoi2 from "../img/pourquoi-clotere-2.webp"




function PourquoiClotereNew(args) {
  return (
    <>
      <Container>
        <div className="relative isolate overflow-hidden px-6 py-20 lg:overflow-visible lg:px-0">

          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">

            
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 items-center">
            <div className="lg:pr-4">
              <img src={Pourquoi2} alt="clotere" />
            </div>
              <div className="lg:pr-4">
                <div className="lg:max-w-lg">
                  <h2 className="text-black leading-[28px] !text-[30px] lg:leading-[30px] lg:text-[45px] font-bold mt-10 sm:mt-0">On vous accompagne de A à Z</h2>
                  <p className="mt-6 text-lg leading-8 text-gray-700">
                  Le passage chez le notaire peut parfois être compliqué, chez Clotere, vous bénéficiez <span className="text-indigo-800 font-bold">d'un conseiller 100% dédié à votre affaire</span>, pour une expérience fluide et sans stress.
                    
                    
                  </p>
                  <ul className="pl-2 gap-y-6">
                    <li className="text-lg mb-4"> 
                    <b>✅ Un accompagnement humain 24/24, 7j/7</b><br/>
                    <span className="text-base text-slate-600">Nos conseillers sont disponibles à tout moment sur WhatsApp.</span>
                    </li>
                    <li className="text-lg mb-4"> 
                    <b>📚 Des guides pratiques et pédagogiques</b><br/>
                    <span className="text-base text-slate-600"> Des ressources claires et complètes pour mieux comprendre.</span>
                    </li>
                    <li className="text-lg"> 
                    <b>🔏 Transparence et temps réel</b><br/>
                    <span className="text-base text-slate-600">Suivez simplement l'avancée de votre dossier chez le notaire.</span>
                    </li>
                  </ul>
                  
                </div>
              </div>

            </div>

          </div>
        </div>
      </Container>
    </>
  );
}

export default PourquoiClotereNew;

