'use client'
import React, { useState, useEffect } from "react";
import { useMemberstack } from "@memberstack/react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu, MenuButton, MenuItem, MenuItems
} from '@headlessui/react'
import { Bars2Icon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom';
import Logo from './img/icn-logo.svg'

import { PlusGrid, PlusGridItem, PlusGridRow } from './annuaire/Plus-grid'
import { Container } from "./annuaire/Container";
import { PhoneIcon, UserIcon } from "@heroicons/react/20/solid";


function DesktopNav() {
  const memberstack = useMemberstack();
  const [member, setMember] = useState(null);

  useEffect(() => {
    if (member === null) {
      memberstack.getCurrentMember()
        .then(({ data: member }) => setMember(member))
    }
  }, []);

  return (
    <nav className="relative hidden lg:flex">
      <PlusGridItem className="relative flex">
        <Link
          to="/trouver-votre-notaire"
          className="flex items-center px-4 py-3 text-base font-medium text-gray-950 bg-blend-multiply data-[hover]:bg-black/[2.5%]"
        >
          Trouvez votre notaire
        </Link>
        <Link
          to="https://clotere.framer.website/?utm_source=clotere"
          target="blank"
          className="flex items-center px-4 py-3 text-base font-medium text-gray-950 bg-blend-multiply data-[hover]:bg-black/[2.5%]"
        >
          Fiches pratiques notaire <span role="img">💡</span>
        </Link>
        {!member &&
          <>
            <Link
              to="/connexion"
              className="flex items-center px-4 py-3 text-base font-medium text-gray-950 bg-blend-multiply data-[hover]:bg-black/[2.5%]"
            >
              Connexion
            </Link>
          </>}
      </PlusGridItem>
      {member &&
        <>
          <Menu as="div" className="relative ml-3">
            <div>
              <MenuButton className="relative flex  text-grey-300 text-lg hidden sm:flex p-4">
                <span className="absolute" />
                <span className="sr-only">Ouvrir le menu client</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-emerald-600">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                </svg>
                {member.auth.email}
              </MenuButton>
            </div>
            <MenuItems
              transition
              className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <MenuItem>
                <Link to="/app/dashboard" className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100">
                  Tableau de bord
                </Link>
              </MenuItem>
              <MenuItem>
                <a className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
                  href="#"
                  onClick={() =>
                    memberstack.logout()
                      .then(({ data, type }) => {
                        window.location.replace('/');
                      })}
                >
                  Deconnexion
                </a>
              </MenuItem>
            </MenuItems>
          </Menu>
        </>
      }
    </nav>
  )
}

function MobileNavButton() {
  return (
    <>
    <div className="flex flex-row gap-2 items-center justify-end">
    <a href="tel:0757831364"
      className="border-1 border-slate-200  flex size-12 items-center justify-center self-center rounded-lg data-[hover]:bg-black/5 lg:hidden"
      aria-label="Appelez un conseiller Clotere"
    >
      <PhoneIcon className="size-6" />
    </a>
    <Link to="connexion"
      className="border-1 border-slate-200  flex size-12 items-center justify-center self-center rounded-lg data-[hover]:bg-black/5 lg:hidden"
      aria-label="Appelez un conseiller Clotere"
    >
      <UserIcon className="size-6" />
    </Link>
    <DisclosureButton
    className="border-1 border-slate-200 flex size-12 items-center justify-center self-center rounded-lg data-[hover]:bg-black/5 lg:hidden"
    aria-label="Open main menu"
  >
    <Bars2Icon className="size-6" />
  </DisclosureButton>
    </div>
    
  </>
  )
}

function MobileNav() {
  const memberstack = useMemberstack();
  const [member, setMember] = useState(null);

  useEffect(() => {
    if (member === null) {
      memberstack.getCurrentMember()
        .then(({ data: member }) => setMember(member))
    }
  }, []);
  return (
    <DisclosurePanel className="lg:hidden bg-white rounded-lg border-slate-100">
      <div class="c_mobile-menu open"><div class="p-8">
        <div class="mb-6">
          <nav class="flex flex-col w-full gap-y-5">
            <Link to="/trouver-votre-notaire" class="text-lg font-medium w-full text-black hover:text-gray-900 laptop:whitespace-nowrap">
              <button class="px-5 py-3 text-base h-[43px]  rounded-full text-black bg-transparent hover:bg-secondary focus:bg-black focus:text-white w-full font-medium transition-all inline-flex items-center justify-center" id="btn_menu_link" data-testid="btn_menu_link" type="button">Trouver votre notaire</button>
            </Link>
            <Link target="blank" class="text-lg font-medium w-full text-black hover:text-gray-900 laptop:whitespace-nowrap" href="https://clotere.framer.website/?utm_source=clotere">

              <button class="px-5 py-3 text-base h-[43px]  rounded-full text-black bg-transparent hover:bg-secondary focus:bg-black focus:text-white w-full font-medium transition-all inline-flex items-center justify-center" id="btn_menu_link" data-testid="btn_menu_link" type="button">Guides & ressources</button>
            </Link>
            <a class="text-lg font-medium w-full text-black hover:text-gray-900 laptop:whitespace-nowrap" href="/fr/a-propos">
              <button class="px-5 py-3 text-base h-[43px]  rounded-full text-black bg-transparent hover:bg-secondary focus:bg-black focus:text-white w-full font-medium transition-all inline-flex items-center justify-center" id="btn_menu_link" data-testid="btn_menu_link" type="button">À propos</button>
            </a>
          </nav>
        </div>
        <div class="grid gap-y-4">
          <Link to="/connexion" class="no-underline font-medium transition-all inline-flex items-center justify-center disabled:bg-[#0000000D] disabled:cursor-not-allowed disabled:text-[#00000033] border-[1px] border-[#0000001A] hover:border-black hover:border-l-2 focus:bg-black focus:text-white px-7 py-[19.5px] text-lg h-md rounded-2xl relative" id="btn_menu_login" data-testid="btn_menu_login">Connexion</Link>
          <Link to="#" class="no-underline font-medium transition-all inline-flex items-center justify-center disabled:bg-[#0000000D] disabled:cursor-not-allowed disabled:text-[#00000033] text-white bg-black hover:bg-[#000000CC] px-7 py-[19.5px] text-lg h-md rounded-2xl relative" id="start_now_header" data-testid="start_now_header" type="button" aria-expanded="false">Débutez ma démarche</Link>
        </div>
      </div>
      </div>
      <div className="absolute left-1/2 w-screen -translate-x-1/2">
        <div className="absolute inset-x-0 top-0 border-t border-black/5" />
        <div className="absolute inset-x-0 top-2 border-t border-black/5" />
      </div>
    </DisclosurePanel>
  )
}

export function Navbar({ banner }) {
  return (
    <div className="relative z-50">
      <Container>
        <Disclosure as="nav" className="pb-4 sm:pb-12">
          <PlusGrid>
            <PlusGridRow className="relative flex justify-between">
              <div className="relative flex gap-6">
                <PlusGridItem className="py-3">
                  <Link to="/" title="Home">
                    <img src={Logo} alt="clotere" />
                  </Link>
                </PlusGridItem>
                {banner && (
                  <div className="relative hidden items-center py-3 lg:flex">
                    {banner}
                  </div>
                )}
              </div>
              <DesktopNav />
              <MobileNavButton />
            </PlusGridRow>
          </PlusGrid>
          <MobileNav />
        </Disclosure>
      </Container>
    </div>
  )
}
