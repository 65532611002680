import React, { useState, useEffect } from "react";
import { useMemberstack, } from "@memberstack/react";
//img et icn
import { ArrowUpRightIcon, RocketLaunchIcon } from '@heroicons/react/20/solid'
import { CheckIcon } from '@heroicons/react/20/solid'
import { DocumentCheckIcon } from "@heroicons/react/24/solid";


const timeline = [
    {
        id: 1,
        content: 'Informations sur votre situation',
        target: 'Etape validée',
        icon: CheckIcon,
        iconBackground: 'bg-slate-700',
    },
    {
        id: 2,
        content: 'Commande',
        target: 'Choisissez votre formule et finalisez votre commande',
        icon: ArrowUpRightIcon,
        iconBackground: 'bg-indigo-600',
    },
    {
        id: 4,
        content: 'Vos actes notariés',
        target: 'Votre notaire valide vos informations et rédige vos actes notariés.',
        icon: DocumentCheckIcon,
        iconBackground: 'bg-slate-300',
    }
]

const includedFeatures = [
    'Vos actes notariés sans vous déplacer !',
    'Sécurité et confidentialité des données',
    'Accompagnement juridique jusqu\'à la signature',
    'Pas de frais cachés',
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function FormPma({ onNextStep }) {
    const memberstack = useMemberstack();
    const [member, setMember] = useState(null);
    const [debut, setDebut] = useState(null);
    const [situation, setSituation] = useState(null);
    const [prenom, setPrenom] = useState(null);
    const [nom, setNom] = useState(null);
    const [telephone, setTelephone] = useState(null);
    const [email, setEmail] = useState("test@gmail.com");
    const [adresse, setAdresse] = useState(null);
    const [cp, setCp] = useState(null);
    const [ville, setVille] = useState(null);
    //step
    const [step1, setStep1] = useState(true);
    const [step2, setStep2] = useState(false);
    const [step3, setStep3] = useState(false);
    const [step4, setStep4] = useState(false);
    const [stepEnd, setStepEnd] = useState(false);
    const [stepPaiement, setStepPaiement] = useState(false);
    const [sendData, setSendData] = useState(false);


    //set member if logged
    useEffect(() => {
        memberstack.getCurrentMember()
            .then(({ data: member }) => setMember(member))
    }, []);


    const handleSubmitCoordonnees = (event) => {
        event.preventDefault();
        setPrenom(event.target.prenom.value);
        setNom(event.target.nom.value);
        setEmail(event.target.email.value);
        setTelephone(event.target.telephone.value);
        setStep3(false);
        setStep4(true);
    }

    const handleSubmitAdresse = (event) => {
        event.preventDefault();
        setAdresse(event.target.adresse.value);
        setCp(event.target.cp.value);
        setVille(event.target.ville.value);
        setStep4(false);
        setStepEnd(true);
    }


    useEffect(() => {
        if (sendData === true) {
            //on envoie la data a zapier
            const sendToZapier = async () => {
                const zapierURL = "https://hooks.zapier.com/hooks/catch/7206204/2kniiw2/"; // 👈 Specify your Zapier Webhook URL here
                try {
                    const response = await fetch(zapierURL, {
                        method: "POST",
                        mode: "cors",
                        body: JSON.stringify({ debut, situation, prenom, nom, telephone, email, adresse, cp, ville })
                    });
                    const res = await response.json();
                    console.log(res);
                } catch (e) {
                    console.log(e);
                }
            };
            sendToZapier();
        }
    }, [sendData]);



    return (
        <>
            <div className="mx-auto w-full min-h-[500px] flex flex-col items-center">

                {/** q1 */}
                {step1 === true &&
                    <div className="max-w-xl">
                        <div>
                            <p className="text-sm font-medium text-slate-500 mb-1">Question <span className="text-indigo-800">1</span>/6</p>
                            <h2 className="text-2xl/9 font-bold tracking-tight text-gray-900">Êtes-vous déjà en processus de PMA (stimulation, FIV, etc.) ?</h2>
                        </div>

                        <div className="mt-10 w-full">
                            <ul className="grid w-full gap-1 md:grid-cols-1 p-0">
                                <li>
                                    <input type="radio" id="oui" name="start" className="hidden peer" required onClick={() => { setDebut("oui"); setStep1(false); setStep2(true);  }} />
                                    <label htmlFor="oui" className="radio-button">
                                        Oui
                                    </label>
                                </li>
                                <li>
                                    <input type="radio" id="non" name="start" className="hidden peer" required onClick={() => { setDebut("non"); setStep1(false); setStep2(true);  }} />
                                    <label htmlFor="non" className="radio-button">
                                        Non
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
                {/** situations */}
                {step2 === true &&
                    <div className="max-w-xl">
                        <div>
                            <p className="text-sm font-medium text-slate-500 mb-1">Question <span className="text-indigo-800">2</span>/6</p>
                            <h2 className=" text-2xl/9 font-bold tracking-tight text-gray-900">Quelle est votre situation ?</h2>
                        </div>

                        <div className="mt-10">
                            <ul className="grid w-full gap-1 md:grid-cols-1 p-0">
                                <li>
                                    <input type="radio" id="marie" name="situation" className="hidden peer" required onClick={() => { setSituation("marie"); setStep2(false); setStep3(true); }} />
                                    <label htmlFor="marie" className="radio-button">
                                        Couple marié
                                    </label>
                                </li>
                                <li>
                                    <input type="radio" id="nonmarie" name="situation" className="hidden peer" required onClick={() => { setSituation("nonmarie"); setStep2(false); setStep3(true); }} />
                                    <label htmlFor="nonmarie" className="radio-button">
                                        Couple non marié
                                    </label>
                                </li>
                                <li>
                                    <input type="radio" id="pacse" name="situation" className="hidden peer" required onClick={() => { setSituation("pacse"); setStep2(false); setStep3(true); }} />
                                    <label htmlFor="pacse" className="radio-button">
                                        Couple pacsé
                                    </label>
                                </li>
                                <li>
                                    <input type="radio" id="autremarie" name="situation" className="hidden peer" required onClick={() => { setSituation("autremarie"); setStep2(false); setStep3(true); }} />
                                    <label htmlFor="autremarie" className="radio-button">
                                        Une des 2 personnes est marié à une autre personne
                                    </label>
                                </li>
                                <li>
                                    <input type="radio" id="autre" name="situation" className="hidden peer" required onClick={() => { setSituation("autre"); setStep2(false); setStep3(true); }} />
                                    <label htmlFor="autre" className="radio-button">
                                        Autre
                                    </label>
                                </li>
                            </ul>

                        </div>
                    </div>
                }

                {/** coordonnées */}
                {step3 === true &&
                    <div className="max-w-xl">
                        <div>
                            <p className="text-sm font-medium text-slate-500 mb-1">Question <span className="text-indigo-800">3</span>/6</p>
                            <h2 className=" text-2xl/9 font-bold tracking-tight text-gray-900">Quelles sont vos coordonnées</h2>
                            <p className="text-sm text-slate-600">Pas d'inquiétude on ne les vendra pas ! C'est uniquement pour vous joindre facilement en cas de besoin.</p>
                        </div>
                        <form action="#" method="POST" className="space-y-6 w-full" data-ms-form="signup" onSubmit={handleSubmitCoordonnees}>
                            <div className="grid sm:grid-cols-2 gap-3">
                                <div>
                                    <label className="block text-base font-medium text-gray-900"> Votre nom<sup>*</sup></label>
                                    <input
                                        required
                                        name="nom"
                                        type="text"
                                        className="input-clotere"
                                    />
                                </div>
                                <div>
                                    <label className="block text-base font-medium text-gray-900">Votre Prénom<sup>*</sup> </label>
                                    <input
                                        required
                                        name="prenom"
                                        type="text"
                                        className="input-clotere"
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="block text-base font-medium text-gray-900">
                                    Votre Email<sup>*</sup>
                                </label>
                                <div className="mt-2">
                                    <input
                                        required
                                        name="email"
                                        type="email"
                                        className="input-clotere"
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="block text-base font-medium text-gray-900">
                                    Votre Téléphone<sup>*</sup>
                                </label>
                                <div className="mt-2">
                                    <input
                                        required
                                        name="telephone"
                                        type="text"
                                        className="input-clotere"
                                    />
                                </div>
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    className="btn-indigo"
                                >
                                    Continuer
                                </button>
                            </div>
                        </form>


                    </div>
                }

                {/** adresse */}
                {step4 === true &&
                    <div className="max-w-xl">
                        <div>
                            <p className="text-sm font-medium text-slate-500 mb-1">Question <span className="text-indigo-800">4</span>/6</p>
                            <h2 className=" text-2xl/9 font-bold tracking-tight text-gray-900">Quelle est votre adresse ?</h2>
                        </div>

                        <div className="mt-10">
                            <form action="#" method="POST" className="space-y-6" data-ms-form="signup" onSubmit={handleSubmitAdresse}>
                                <div>
                                    <label className="block text-base font-medium text-gray-900">
                                        Adresse<sup>*</sup>
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            required
                                            name="adresse"
                                            type="text"
                                            className="input-clotere"
                                        />
                                    </div>
                                </div>
                                <div className="grid sm:grid-cols-2 gap-3">
                                    <div>
                                        <label className="block text-base font-medium text-gray-900"> Code postal<sup>*</sup></label>
                                        <input
                                            required
                                            name="cp"
                                            type="text"
                                            className="input-clotere"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-base font-medium text-gray-900">Ville<sup>*</sup> </label>
                                        <input
                                            required
                                            name="ville"
                                            type="text"
                                            className="input-clotere"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <button
                                    onClick={() => {onNextStep(2);}}
                                    className="btn-indigo"
                                        type="submit"
                                    >
                                        Continuer
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>
                }



                {/** etape intermédiaire */}
                {stepEnd === true &&
                    <div className="max-w-xl">
                        <div className="">
                            <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-green-100">
                                <CheckIcon aria-hidden="true" className="size-6 text-green-600" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <h2 as="h3" className=" text-2xl/9 font-bold tracking-tight text-gray-900">
                                    Nous avons les informations nécessaires. Voici les prochaines étapes :
                                </h2>
                                <div className="flow-root relative mb-3">
                                    <ul role="list" className=" mt-10">
                                        {timeline.map((event, eventIdx) => (
                                            <li key={event.id}>
                                                <div className="relative pb-3">
                                                    {eventIdx !== timeline.length - 1 ? (
                                                        <span aria-hidden="true" className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" />
                                                    ) : null}
                                                    <div className="relative flex space-x-3 items-center">
                                                        <div>
                                                            <span
                                                                className={classNames(
                                                                    event.iconBackground,
                                                                    'flex size-8 items-center justify-center rounded-full ring-8 ring-white',
                                                                )}
                                                            >
                                                                <event.icon aria-hidden="true" className="size-5 text-white" />
                                                            </span>
                                                        </div>
                                                        <div className="flex min-w-0 flex-1 justify-start space-x-4 pt-1.5 text-left">
                                                            <div>
                                                                <p className="text-sm text-gray-500 m-0">
                                                                    {event.content}<br />{' '}
                                                                    <span className="font-medium text-black">{event.target}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>

                                </div>
                                <a
                                    href="#"
                                    onClick={() => { setStepEnd(false); setStepPaiement(true); setSendData(true) }}
                                    className="btn-indigo"
                                >
                                    Continuer
                                </a>
                            </div>

                        </div>
                    </div>
                }

                {/** etape paiement */}
                {stepPaiement === true &&
                    <>
                        <div className="mt-10">
                            <div className="bg-white">
                                <div className="mx-auto  px-6 lg:px-8">
                                    <h2 className="font-bold text-center">Une offre unique sans frais cachés</h2>

                                    <div className="mx-auto mt-8  rounded-3xl ring-1 ring-gray-200 lg:mx-0 lg:flex lg:max-w-none">
                                        <div className="p-8 sm:p-10 lg:flex-auto">
                                            <div className="hidden sm:block">
                                            <h3 className="text-3xl font-semibold tracking-tight text-gray-900">On s'occupe de tout !</h3>
                                            <p className="mt-6 text-base/7 text-gray-600">
                                                Clotere.fr simplifie la création de votre dossier notarial et vous connecte à un notaire réactif pour un traitement rapide. Votre conseiller dédié est disponible 24/7 pour répondre à vos questions et vous accompagner. 🚀
                                            </p>
                                            </div>
                                            <div className="mt-10 flex items-center gap-x-4">
                                                <h4 className="flex-none text-sm/6 font-semibold text-indigo-600">Détails de l'offre</h4>
                                                <div className="h-px flex-auto bg-gray-100" />
                                            </div>
                                            <ul role="list" className="mt-8 grid grid-cols-1 gap-4 text-sm/6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                                                {includedFeatures.map((feature) => (
                                                    <li key={feature} className="flex gap-x-3">
                                                        <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-indigo-600" />
                                                        {feature}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:shrink-0">
                                            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                                                <div className="mx-auto max-w-xs px-8">
                                                    <p className="text-base font-semibold text-gray-600">Payez une fois, sans frais caché</p>
                                                    <p className="mt-6 flex items-baseline justify-center gap-x-2">
                                                        <span className="text-5xl font-semibold tracking-tight text-gray-900">239€</span>
                                                        
                                                    </p>
                                                    <a
                                                        href={"https://buy.stripe.com/bIYeWSdwpgfH0aQ145?prefilled_email=" + email}
                                                        className="no-underline mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    >
                                                        Valider mon offire
                                                    </a>
                                                    <p className="mt-6 text-xs/5 text-gray-600">
                                                        Paiement 100% sécurisé via notre partenaire Stripe
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </>
                }


            </div>
        </>
    );
}


export default FormPma;