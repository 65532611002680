import React, { useState } from "react";
import {
    CalendarIcon,
    MapPinIcon,
} from '@heroicons/react/20/solid'

const RdvNotaireSelection = ({ rdvNotaire, id_affaire, type }) => {
    //console.log("rdvNotaire", rdvNotaire)
    // État pour suivre le créneau sélectionné
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [isConfirmed, setIsConfirmed] = useState(false);

    // Vérification si rdvNotaire existe
    if (!rdvNotaire) return null;

    // Fonction pour gérer la sélection et envoyer à Zapier
    const handleSelection = async (selectedSlot) => {
        console.log(selectedSlot, id_affaire, type)

        const payload = {
            id_affaire: id_affaire,
            id_rdv: rdvNotaire.id,
            selected_date: selectedSlot,
            type: type,
        };

        console.log("📩 Données envoyées :", JSON.stringify(payload, null, 2));

        fetch(`https://hooks.zapier.com/hooks/catch/7206204/2wu6c0i/`, {
            method: "POST",
            "Content-Type": "application/json",
            body: JSON.stringify(payload),
        })
            .then(async (response) => {
                if (!response.ok) {
                    throw new Error(`HTTP Error! Statut : ${response.status}`);
                }
                // ✅ Afficher un message de succès
                setIsConfirmed(true);



            })
            .catch((error) => {
                console.error("❌ Erreur lors de l'envoi à Zapier :", error);
                alert("🚨 Une erreur est survenue lors de l'envoi des données.");
            });
    };

    return (
        <>
            <p className="text-lg"> 🗓️ Votre rendez-vous avec votre notaire</p>
            {/** si créneau déjà trouvé affichage du rdv */}
            {rdvNotaire.statut === "programme" && <>
                <div className="mb-10 relative flex flex-col flex-wrap justify-start px-4 py-4 hover:bg-gray-50 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
                <ul className="p-0">
            <li className="relative flex flex-wrap justify-between items-center ">
                <div className="flex min-w-0 gap-x-4 flex-wrap">
                    <span className="relative inline-block sm:block">
                    <CalendarIcon className=" size-12 text-gray-400" aria-hidden="true" />

                    </span>
                    <div className="min-w-0 flex-auto">
                        <p className="text-xl font-semibold leading-6 text-gray-900 mb-2">

                            <span className="absolute inset-x-0 bottom-0" />
                            Rendez-vous avec votre notaire

                        </p>
                        <div className="flex flex-row flex-wrap items-center gap-4">
                            
                            <p className="mt-1 flex flex-wrap text-md leading-5 text-indigo-800 font-bold  items-center gap-2 mb-0">
                                <CalendarIcon className="size-5 text-gray-400 " />  {rdvNotaire.date}
                            </p>
                            <p className="mt-1 flex flex-wrap text-md leading-5 text-gray-500 mb-0">
                                <MapPinIcon className="size-5 text-gray-400" /> {rdvNotaire.type_reunion === "À distance" ? (<>Visio-conférence</>) : (<>Présentiel</>)}
                            </p>
                        </div>
                    </div>
                </div>
                <a className="btn-main no-underline mt-3 sm:m-0 w-full " >Annuler</a>
            </li>
        </ul>
                </div>
            </>}

            {/** si créneau pas trouvé affichage form */}
            {rdvNotaire.statut === "a programme" && <>

                <div className="mb-10 relative flex flex-col flex-wrap justify-start px-4 py-4 hover:bg-gray-50 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
                    {/* Affichage du message de confirmation si le rendez-vous est validé */}
                    {isConfirmed ? (
                        <div className="flex flex-col text-center">
                            <p className="font-bold">Rendez-vous confirmé</p>
                            <ul className="flex flex-row flex-wrap justify-center list-none gap-4 m-0 p-0">
                                <li
                                    className={`p-3 rounded-lg font-bold text-lg text-center cursor-pointer transition items-center flex ${selectedSlot === rdvNotaire.dispo_notaire_1 ? "bg-green-200 text-green-900" : "bg-indigo-50 hover:bg-indigo-200 text-gray-900"
                                        }`}
                                >
                                    <CalendarIcon className="inline-flex mr-2 size-6 text-indigo-600" />
                                    {selectedSlot}
                                </li>

                            </ul>
                            <a href="#">Ajoutez à votre agenda</a>
                        </div>
                    ) : (
                        <>
                            <div className="flex flex-col text-center">
                                <p className="font-bold">Votre notaire vous propose des créneaux pour votre rendez-vous. <br />Quel créneau vous conviendrait ?</p>
                                <ul className="flex flex-row flex-wrap justify-center list-none gap-4 m-0 p-0">
                                    {/* Créneau 1 */}
                                    {rdvNotaire.dispo_notaire_1 && (
                                        <li
                                            className={`p-3 rounded-lg font-bold text-lg text-center cursor-pointer transition items-center flex ${selectedSlot === rdvNotaire.dispo_notaire_1 ? "bg-green-200 text-green-900" : "bg-indigo-50 hover:bg-indigo-200 text-gray-900"
                                                }`}
                                            onClick={() => handleSelection(rdvNotaire.dispo_notaire_1)}
                                        >
                                            <CalendarIcon className="inline-flex mr-2 size-6 text-indigo-600" />
                                            {rdvNotaire.dispo_notaire_1}
                                        </li>
                                    )}

                                    {/* Créneau 2 */}
                                    {rdvNotaire.dispo_notaire_2 && (
                                        <li
                                            className={`p-3 rounded-lg font-bold text-lg text-center cursor-pointer transition items-center flex ${selectedSlot === rdvNotaire.dispo_notaire_2 ? "bg-green-200 text-green-900" : "bg-indigo-50 hover:bg-indigo-200 text-gray-900"
                                                }`}
                                            onClick={() => handleSelection(rdvNotaire.dispo_notaire_2)}
                                        >
                                            <CalendarIcon className="inline-flex mr-2 size-6 text-indigo-600" />
                                            {rdvNotaire.dispo_notaire_2}
                                        </li>
                                    )}

                                    {/* Créneau 3 */}
                                    {rdvNotaire.dispo_notaire_3 && (
                                        <li
                                            className={`p-3 rounded-lg font-bold text-lg text-center cursor-pointer transition items-center flex ${selectedSlot === rdvNotaire.dispo_notaire_3 ? "bg-green-200 text-green-900" : "bg-indigo-50 hover:bg-indigo-200 text-gray-900"
                                                }`}
                                            onClick={() => handleSelection(rdvNotaire.dispo_notaire_3)}
                                        >
                                            <CalendarIcon className="inline-flex mr-2 size-6 text-indigo-600" />
                                            {rdvNotaire.dispo_notaire_3}
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </>
                    )}
                </div>
            </>}
        </>
    );
};

export default RdvNotaireSelection;