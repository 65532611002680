import React, { useEffect, useState } from "react";
import Alert from "../components/Alert"
import { Link } from 'react-router-dom';
import { HomeS } from "./ChoisirNotaire";
import { useParams } from "react-router";
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';

function RelireDocument(args) {
    const { id_event } = useParams();
    //console.log("event", args);
    const [infoEvent, setInfoEvent] = useState(null);
    const [validation, setValidation] = useState(false);
    const [erreur, setErreur] = useState(false);
    const navigate = useNavigate();


    //get info event
    const getInfoEvent = async () => {
        const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/event/${id_event}`;

        fetch(
            URL,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                    'content-type': 'application/x-www-form-urlencoded',
                    "Accept": "application/json, text/plain, /"
                },
            })
            .then((res) => res.json())
            .then((res) => {
                //console.log("info event", res.fields.document_from_document[0].url);
                setInfoEvent(res.fields);
                return
            })
            .catch((error) => console.log(error));

    };

    //use effect event
    useEffect(() => {
        getInfoEvent();
    }, []);

    const sendToZapier = async () => {
        const zapierURL = "https://hooks.zapier.com/hooks/catch/7206204/22qgvsi/"; // 👈 Specify your Zapier Webhook URL here
        try {
            const response = await fetch(zapierURL, {
                method: "POST",
                mode: "cors",
                body: JSON.stringify({ id_event,type:"pma" })
            });
            const resp = await response.json();
            console.log(resp);
        } catch (e) {
            console.log(e);
        }
    };

    //signalement erreur doc
    const handleSubmit = (event) => {
        event.preventDefault();
        const erreurI = event.target.erreurInput.value;
        const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/event/${id_event}`;
        fetch(
            URL,
            {
                method: "PATCH",
                headers: {
                    Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                    "Accept": "application/json",
                    'content-type': "application/json"
                },
                body: JSON.stringify({
                    "fields": {
                        "erreurs": `message de l'erreur :  ${erreurI} `,
                        "etat": "erreurs signalées"
                    }
                })
            })
            .then((res) => res.json())
            .then((res) =>
                setErreur(true),
                sendToZapier(),
                setTimeout(() => {
                    setErreur(false);
                    navigate("/app/dashboard");
                }, 3000)
            );


    }


    const validationDocument = async () => {
        var date = new Date();
        const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/event/${id_event}`;
        fetch(
            URL,
            {
                method: "PATCH",
                headers: {
                    Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                    "Accept": "application/json",
                    'content-type': "application/json"
                },
                body: JSON.stringify({
                    "fields": {
                        "validation": `validé par le vendeur ou acheteur le ${date}  `,
                    }
                })
            })
            .then((res) => res.json())
            .then((res) =>
                setValidation(true),
                setTimeout(() => {
                    navigate("/app/dashboard");
                }, 3000)
            );
    }


    if (infoEvent !== null) {
        return (
            <>
                <div className='container'>
                    <Link to="/app/dashboard" className="mt-8 d-flex flex-col no-underline text-slate-800">
                        <p className="m-0 inline-flex"><ArrowLeftIcon className='size-6 flex' /> {"        "} Retour</p>
                    </Link>
                    <div className='mt-10'>
                        <h3 className="mt-3">Relisez votre document</h3>
                        <p>Si vous constatez des erreurs dans le document n'hésitez pas à nous le signaler, nous avertiront votre notaire.</p>
                    </div>
                    <div className="mt-10">
                        <div class="grid grid-cols-3 gap-4">
                            <div class="col-span-2">
                                <iframe title="document" src={infoEvent.document_from_document[0].url} width={"100%"} height={"600px"}></iframe>
                            </div>

                            <div className="overflow-hidden rounded-lg bg-white shadow">
                                <div className="p-3">
                                    {validation === true && <>
                                        <Alert type="success" message="👍 Document validé. Nous transmettons l'information à votre notaire."/>
                                    </>}
                                    <button className="btn-indigo w-full" onClick={validationDocument}>Valider le document</button>
                                    <p>Cela veut dire que vous ne constater pas d'erreur sur le document et qu'il peux être signé en l'état.</p>
                                    <br />
                                    <h6>Vous constatez des erreurs ?</h6>
                                    <form onSubmit={handleSubmit}>
                                        {erreur === true && <>
                                            <Alert type="error" message="C'est noté, nous transmettons l'erreur à votre notaire."/>
                                        </>}
                                        <textarea
                                            placeholder="Dites nous ce qui ne va pas sur le document"
                                            name="erreurInput"
                                            rows={4}
                                            className="input-clotere"
                                        />
                                        <button className="btn-link mt-2" type="submit">Signaler une erreur</button>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </>
        );
    }
    return (<><HomeS>Chargement...</HomeS></>)
}

export default RelireDocument;