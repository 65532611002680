import React, { useState } from "react";
import {
    Container, Row, Col,
    Offcanvas
} from "reactstrap";
import { TitlePageBig, TitlePageApp } from "../style/Layout";
import { EnvelopeIcon, PhoneIcon, ChatBubbleLeftEllipsisIcon } from "@heroicons/react/16/solid";

//feature whatsapp
const phoneNumber = "33753585540";
const openWhatsApp = () => {
    window.open(`https://wa.me/${phoneNumber}`, "_blank");
};

function Interlocuteurs(args) {
    // console.log("arg interloc", args);
    const transacInfo = args.transaction;
    const [canvas, setCanvas] = useState(false);
    const toggle = () => setCanvas(!canvas);
    return (
        <>
            <Container>
                <Row className="d-flex align-self-start">
                    <TitlePageApp>
                        <Col md="7"><TitlePageBig className="mb-4">Vos interlocuteurs</TitlePageBig></Col>
                    </TitlePageApp>
                    <Row>
                        <ul
                            className="gap-6"
                        >
                            {/** notaire */}
                            {transacInfo.statut_marketplace_notaire === "notaire trouvé" &&
                                <li className="relative flex  justify-between gap-x-6 px-4 py-4 hover:bg-gray-50 sm:px-6 divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl flex-wrap">
                                    <div className="flex flex-column sm:flex-row min-w-0 gap-x-4">
                                        <span className="inline-block h-14 w-14 overflow-hidden rounded-full bg-gray-100 mb-3">
                                            <svg fill="currentColor" viewBox="0 0 24 24" className="h-full w-full text-gray-300">
                                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                            </svg>
                                        </span>
                                        <div className="min-w-0 flex-auto">
                                            <p className="text-xl font-semibold leading-6 text-gray-900 m-0">

                                                <span className="absolute inset-x-0 bottom-0" />
                                                {transacInfo.prenom_from_notaire} {transacInfo.nom_from_notaire}
                                                <span className="ml-3 inline-flex items-center rounded-md bg-orange-200 px-2 py-1 text-sm font-medium text-orange-600 ring-1 ring-inset ring-gray-500/10">
                                                    Notaire
                                                </span>

                                            </p>
                                            <div className="flex  items-center gap-4 sm:flex-column">
                                                <p className="mt-1 flex text-mg leading-5 text-gray-500  items-center gap-2 mb-0">
                                                    <EnvelopeIcon className="size-4 text-gray-600 " /> {transacInfo.email_from_notaire}
                                                </p>
                                                <p className="mt-1 flex text-md leading-5 text-gray-500  mb-0">
                                                    <PhoneIcon className="size-4 text-gray-600" /> {transacInfo.telephone_from_notaire}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex shrink-0 items-center gap-x-4 mt-3 sm:mt-0">
                                        <a className="btn-main  no-underline" href={`mailto:${transacInfo.email_from_notaire}`}>Contacter</a>
                                    </div>
                                </li>
                            }
                            

                            {/** vendeur */}
                            {transacInfo.prenom_from_vendeur &&
                                <li className="mt-4 relative flex flex-wrap justify-between gap-x-6 px-4 py-4 hover:bg-gray-50 sm:px-6 divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl flex-wrap">
                                    <div className="flex flex-column sm:flex-row min-w-0 gap-x-4">
                                        <span className="inline-block h-14 w-14 overflow-hidden rounded-full bg-gray-100 mb-3">
                                            <svg fill="currentColor" viewBox="0 0 24 24" className="h-full w-full text-gray-300">
                                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                            </svg>
                                        </span>
                                        <div className="min-w-0 flex-auto">
                                            <p className="text-xl font-semibold leading-6 text-gray-900 m-0">

                                                <span className="absolute inset-x-0 bottom-0" />
                                                {transacInfo.prenom_from_vendeur} {transacInfo.nom_from_vendeur}
                                                <span className="ml-3 inline-flex items-center rounded-md bg-green-200 px-2 py-1 text-sm font-medium text-green-600 ring-1 ring-inset ring-gray-500/10">
                                                    Vendeur
                                                </span>

                                            </p>
                                            <div className="flex  items-center gap-4 sm:flex-column">
                                                <p className="mt-1 flex text-mg leading-5 text-gray-500  items-center gap-2 mb-0">
                                                    <EnvelopeIcon className="size-4 text-gray-600 " /> {transacInfo.email_from_vendeur}
                                                </p>
                                                <p className="mt-1 flex text-md leading-5 text-gray-500  mb-0">
                                                    <PhoneIcon className="size-4 text-gray-600" /> {transacInfo.telephone_from_vendeur}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex shrink-0 items-center gap-x-4 mt-3 sm:mt-0">
                                        <a className="btn-main  no-underline" href={`mailto:${transacInfo.email_from_vendeur}`}>Contacter</a>
                                    </div>
                                </li>
                            }

                            {/** acheteur */}
                            {transacInfo.prenom_from_acheteur &&
                                <li className="mt-4 relative flex flex-wrap justify-between gap-x-6 px-4 py-4 hover:bg-gray-50 sm:px-6 divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
                                    <div className="flex flex-column min-w-0 gap-x-4">
                                        <span className="inline-block h-14 w-14 overflow-hidden rounded-full bg-gray-100 mb-3">
                                            <svg fill="currentColor" viewBox="0 0 24 24" className="h-full w-full text-gray-300">
                                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                            </svg>
                                        </span>
                                        <div className="min-w-0 flex-auto">
                                            <p className="text-xl font-semibold leading-6 text-gray-900 m-0">

                                                <span className="absolute inset-x-0 bottom-0" />
                                                {transacInfo.prenom_from_acheteur} {transacInfo.nom_from_acheteur}
                                                <span className="ml-3 inline-flex items-center rounded-md bg-indigo-200 px-2 py-1 text-sm font-medium text-indigo-600 ring-1 ring-inset ring-gray-500/10">
                                                    Acheteur
                                                </span>

                                            </p>
                                            <div className="flex flex-row items-center gap-4 mt-2">
                                                <p className="mt-1 flex text-md leading-5 text-gray-500  items-center gap-2 mb-0">
                                                    <EnvelopeIcon className="size-4 text-gray-600 " /> {transacInfo.email_from_acheteur}
                                                </p>
                                                <p className="mt-1 flex text-md leading-5 text-gray-500 mb-0">
                                                    <PhoneIcon className="size-4 text-gray-600" /> {transacInfo.telephone_from_acheteur}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex shrink-0 items-center gap-x-4 mt-3 sm:mt-0">
                                        <a className="btn-main no-underline" href={`mailto:${transacInfo.email_from_acheteur}`}>Contacter</a>
                                    </div>
                                </li>
                            }



                            {/** conseiller */}
                            {transacInfo.prenom_from_agent &&
                                <li className="mt-4 relative flex flex-wrap  justify-between gap-x-6 px-4 py-4 hover:bg-gray-50 sm:px-6 divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
                                    <div className="flex flex-column min-w-0 gap-x-4">
                                        <span className="inline-block h-14 w-14 overflow-hidden rounded-full bg-gray-100 mb-3">
                                            <svg fill="currentColor" viewBox="0 0 24 24" className="h-full w-full text-gray-300">
                                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                            </svg>
                                        </span>
                                        <div className="min-w-0 flex-auto">
                                            <p className="text-xl font-semibold leading-6 text-gray-900 m-0">

                                                <span className="absolute inset-x-0 bottom-0" />
                                                {transacInfo.prenom_from_agent} {transacInfo.nom_from_agent}
                                                <span className="ml-3 inline-flex items-center rounded-md bg-teal-200 px-2 py-1 text-sm font-medium text-teal-600 ring-1 ring-inset ring-gray-500/10">
                                                    Votre conseiller
                                                </span>

                                            </p>
                                            <div className="flex flex-row items-center gap-4 mt-2">
                                                <p className="mt-1 flex text-md leading-5 text-gray-500  items-center gap-2 mb-0">
                                                    <EnvelopeIcon className="size-4 text-gray-600 " /> contact@clotere.fr
                                                </p>
                                                <p className="mt-1 flex text-md leading-5 text-gray-500 mb-0">
                                                    <PhoneIcon className="size-4 text-gray-600" /> 07 53 58 55 40
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex shrink-0 items-center gap-x-4 mt-3 sm:mt-0">
                                        <button
                                            onClick={openWhatsApp}
                                            className="flex items-center px-2 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition text-sm font-bold"
                                        >
                                            <ChatBubbleLeftEllipsisIcon className="h-4 w-4 mr-2" />
                                            Contacter sur Whatsapp
                                        </button>
                                        <a className="btn-main no-underline" href={`mailto:${transacInfo.email_from_agent}`}>Contacter</a>
                                    </div>
                                </li>
                            }


                        </ul>
                    </Row>
                </Row>
            </Container>
            <Offcanvas
                isOpen={canvas}
                toggle={toggle}
                {...args}
                direction="end"
                scrollable></Offcanvas>
        </>
    );
}

export default Interlocuteurs;