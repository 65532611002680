import React from "react";
import { HeartIcon, HomeModernIcon, ShieldCheckIcon,BookmarkIcon, CheckBadgeIcon, UserCircleIcon  } from "@heroicons/react/20/solid";
import { useNavigate } from 'react-router-dom';
import conseiller from "../img/conseillers.webp"

function HeaderNew() {

    const navigate = useNavigate();

    return (
        <div className="bg-white z-10">
           

            <div className="relative isolate px-6  lg:px-8">
                <div
                    aria-hidden="true"
                    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                >
                    <div
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                    />
                </div>
                <div className="relative max-w-5xl mx-auto sm:pt-8">
                    <div className="text-center">
                    <div class="mx-auto flex flex-row gap-3 mb-4 items-center justify-center w-fit p-2 rounded-full bg-gradient-to-br to-teal-100 from-transparent">
                               
                               <img src={conseiller} alt="conseillé" className="block w-auto h-6 object-contain"/>
                                    <p className="text-sm m-0 font-medium">Nos conseillers vous accompagne gratuitement</p>

                            </div>
                        <h1 className="mb-4 !text-slate-900 font-extrabold text-4xl sm:text-[70px] leading-[4rem] sm:leading-[5rem] tracking-tight text-center">
                            Passez chez le <span className="underline decoration-teal-500 decoration-wavy underline-offset-8">notaire</span> simplement.
                        </h1>
                        <p className="text-pretty text-lg font-medium text-gray-800 sm:text-xl/8">
                            Un accompagnement de A à Z pour <b>gérer vos démarches chez le notaire</b> simplement.
                        </p>
                        <p className="mt-5 mb-3 text-pretty text-lg font-medium text-gray-800 sm:text-xl/12">
                            De quoi avez-vous besoin ?
                        </p>
                        {/** cards */}
                        <div className="flex flex-row flex-wrap justify-center items-center gap-3 sm:gap-8 relative flex-[0_0_auto] mb-3">

                            <div onClick={() => navigate('/faire-une-donation')} className="link-home cursor-pointer">
                                
                                <HeartIcon className="size-8 " />
                                <h2 className="m-0 relative w-fit  font-semibold text-black !text-[16px] sm:!text-[20px]  text-left sm:text-center tracking-[0] leading-[normal]">
                                    Faire une donation
                                </h2>
                            </div>

                            <div onClick={() => navigate('/notaire-acheteur')} className="link-home cursor-pointer">
                                <HomeModernIcon className="size-8" />
                                <h2 className="m-0 relative w-fit  font-semibold text-black !text-[16px] sm:!text-[20px]  text-left sm:text-center tracking-[0] leading-[normal]">
                                    Achetez votre bien immobilier
                                </h2>
                            </div>

                            <div onClick={() => navigate('/inscription/vente')} className="link-home cursor-pointer">
                                <ShieldCheckIcon className="size-8" />
                                <h2 className="m-0 relative w-fit  font-semibold text-black !text-[16px] sm:!text-[20px]   text-left sm:text-center tracking-[0] leading-[normal]">
                                    Vendre son bien immobilier
                                </h2>
                            </div>
                            <div onClick={() => navigate('/notaire-contrat-mariage')} className="link-home cursor-pointer">
                                <CheckBadgeIcon className="size-8" />
                                <h2 className="m-0 relative w-fit  font-semibold text-black !text-[16px] sm:!text-[20px]  text-left sm:text-center tracking-[0] leading-[normal]">
                                    Réaliser un contrat de mariage
                                </h2>
                            </div>
                            <div onClick={() => navigate('/notaire-reconnaissance-conjointe-enfant')} className="link-home cursor-pointer">
                                <UserCircleIcon className="size-8" />
                                <h2 className="m-0 relative w-fit  font-semibold text-black !text-[16px] sm:!text-[20px]  text-left sm:text-center tracking-[0] leading-[normal]">
                                    Reconnaissante conjointe anticipée (PMA)
                                </h2>
                            </div>
                            {/** 
                            <div onClick={() => navigate('/notaire-divorce')} className="link-home cursor-pointer">
                                <BookmarkIcon className="size-8" />
                                <h2 className="m-0 relative w-fit  font-semibold text-black !text-[16px] sm:!text-[20px]   text-left sm:text-center tracking-[0] leading-[normal]">
                                    Divorcer
                                </h2>
                            </div>
                            <div onClick={() => navigate('/notaire-adoption')} className="link-home cursor-pointer">
                                <BookmarkIcon className="size-8" />
                                <h2 className="m-0 relative w-fit  font-semibold text-black !text-[16px] sm:!text-[20px]   text-left sm:text-center tracking-[0] leading-[normal]">
                                    Adopter
                                </h2>
                            </div>
                            */}
                            
                        </div>
                        <div className="mt-10 flex items-center justify-center gap-x-6"></div>
                    </div>
                </div>
                <div
                    aria-hidden="true"
                    className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                >
                    <div
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                        className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                    />
                </div>
            </div>
        </div>
    );
}

export default HeaderNew;
