import React from "react";
import { Link } from 'react-router-dom';
import ClotereLogo from "../img/logo-clotere-blanc.svg";


const navigationFooter = {
    solutions: [
        { name: 'Trouver un notaire acheteur immobilier', href: '/notaire-acheteur' },
        { name: 'Trouver un notaire pour vendre son bien', href: '#' },
        { name: 'Trouver un notaire pour une donation', href: '/faire-une-donation' },
        { name: 'Trouver un notaire pour un contrat de mariage', href: '/notaire-contrat-mariage' },
        { name: 'Trouver un notaire pour une reconnaissance conjointe d\'enfant', href: '/notaire-reconnaissance-conjointe-enfant' },
        { name: 'Trouver un notaire pour une procédure d\'adoption', href: '/notaire-adoption' },
    ],
    guides: [
        { name: 'Notaire et donation', href: 'https://clotere.framer.website/categories/notaire-donation?utm_source=clotere' },
        { name: 'Notaire et immobilier', href: 'https://clotere.framer.website/categories/notaire-immobilier?utm_source=clotere' },
        { name: 'Un ou deux notaire pour votre achat immobilier ?', href: 'https://clotere.framer.website/blog/un-ou-deux-notaires-pour-votre-achat-immobilier?utm_source=clotere' },
        { name: 'Blog : Tout comprendre sur les notaires', href: 'https://clotere.framer.website/?utm_source=clotere' },
    ],
    social: [
        {
            name: 'Instagram',
            href: 'https://www.instagram.com/clotere_notaire/?hl=fr',
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
    ],
}



function Footer() {
    return (
        <div>
            {/** footer */}
            <footer className="bg-gray-900 sm:mt-12">
                <div className="mx-auto max-w-7xl px-6 pb-8 pt-16">
                    <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                        <div className="space-y-8">
                            <img
                                alt="Clotere"
                                src={ClotereLogo}
                                className="h-9"
                            />
                            <p className="text-balance text-sm/6 text-gray-300">
                                Clotere.fr simplifie votre passage chez le notaire | Donation, achat vente immobilière, Pacs....etc
                            </p>
                            <div className="flex gap-x-6">
                                {navigationFooter.social.map((item) => (
                                    <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-300">
                                        <span className="sr-only">{item.name}</span>
                                        <item.icon aria-hidden="true" className="size-6" />
                                    </a>
                                ))}
                            </div>
                        </div>
                        <div className="mt-16 grid grid-cols-1 gap-8 xl:col-span-2 xl:mt-0">
                            <div className="md:grid md:grid-cols-2 md:gap-8">
                                <div>
                                    <h3 className="text-sm/6 font-semibold text-white">Nos services</h3>
                                    <ul className="mt-6 space-y-4 p-0">
                                        {navigationFooter.solutions.map((item) => (
                                            <li key={item.name}>
                                                <Link to={item.href} className="text-sm/6 text-gray-400 hover:text-white no-underline">
                                                    {item.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="mt-10 md:mt-0">
                                    <h3 className="text-sm/6 font-semibold text-white">Nos guides et ressources</h3>
                                    <ul className="mt-6 space-y-4 p-0">
                                        {navigationFooter.guides.map((item) => (
                                            <li key={item.name}>
                                                <a href={item.href} target="blank" className="text-sm/6 text-gray-400 hover:text-white">
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-16 border-t border-white/10 pt-8">

                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div><p className="text-sm/6 text-gray-400">&copy; 2025 Clotere, tout droits réservés. Clotere n'est pas un notaire</p></div>
                            <div className="flex flex-row space-x-6 justify-end">
                                <Link to="/info/mentions-legales" className="text-sm/6 text-white no-underline">Mentions légales</Link>
                                <Link to="/info/cgu" className="text-sm/6 text-white no-underline">CGU</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>

    );
}

export default Footer;