import React, { useState, useEffect } from "react";
import { useMemberstack, } from "@memberstack/react";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { Widget } from '@typeform/embed-react';
import { useParams } from "react-router";
import { NavbarOnboard } from '../NavbarOnboard';
import { CheckIcon, PhoneIcon } from "@heroicons/react/20/solid";
import FormContratMariage from "../components/FormContratMariage";
import FormPma from "../components/FormPma";
import StepperOnboard from "../components/StepperOnboard"
//img 
import onboard1 from "../img/onboard-1.png";
import onboard2 from "../img/onboard-2.png";
import onboard3 from "../img/onboard-3.png";

const WidgetTypeform = styled(Widget)`
width:100%;
height:80%;
`;


const stepsData = [
    { id: '1', name: 'Projet', description: "test", href: '#', status: 'current' },
    { id: '2', name: 'Commande', href: '#', status: 'upcoming' },
    { id: '3', name: 'Dossier Juridique', href: '#', status: 'upcoming' },
];

function Onboard(args, props) {
    const memberstack = useMemberstack();
    const [member, setMember] = useState(null);
    const navigate = useNavigate();
    const { type } = useParams();
    console.log("type", type);
    const [debuter, setDebuter] = useState(false);

    //redirect if already logged
    useEffect(() => {
        memberstack.getCurrentMember()
            .then(({ data: member }) => setMember(member))
    }, []);

    //console.log("member",member)
    // Gérer l'étape actuelle avec useState
    const [steps, setSteps] = useState(stepsData);

    // Fonction pour passer à l'étape suivante
    const onNextStep = (stepNumber) => {
        //console.log("Changement vers l'étape :", stepNumber);
        const updatedSteps = steps.map((step, i) => ({
            ...step,
            status: i === stepNumber ? 'current' : i < stepNumber ? 'complete' : 'upcoming'
        }));
        setSteps(updatedSteps);
    };


    return (
        <>
            <div className="bg-gradient-to-t from-teal-100 to-teal-50 min-h-screen h-full ">
                <NavbarOnboard />
                <div className="container mt-4">
                    <div class="flex flex-wrap">
                        <div class="w-full text-center">
                            <StepperOnboard steps={steps} />
                            {debuter === false && <>
                                <div className="sm:max-w-[75%] mx-auto mb-10">
                                    <p className="text-3xl font-bold sm:mt-14 mt-8">Quelques informations pour <b className="text-indigo-800 font-bold">débuter votre dossier chez le notaire</b></p>
                                    <p className="text-lg">Merci de nous faire confiance, votre notaire va prendre en charge votre affaire <b>Ref F-6577669</b></p>
                                </div>
                                {/** card notaire  
                            <div
                                className="mt-2 mb-2 sm:mt-5 relative flex items-center space-x-3 rounded-lg "
                            >
                                <div className="shrink-0">
                                    <img alt="Votre notaire" src={Maitre} className="size-16 sm:size-24 rounded-full" />
                                </div>
                                <div className="min-w-0 flex-1">
                                    <span aria-hidden="true" className="absolute inset-0" />
                                    <p className="text-xl font-medium text-gray-900 no-underline m-0">Philippe Vesse</p>
                                    <span className="inline-flex items-center rounded-md bg-indigo-800 px-2 py-1 text-xs font-medium text-white">
                                        <CheckIcon className="size-4" /> Notaire recommandé
                                    </span>
                                </div>
                            </div>
                            **/}

                                <div class="grid grid-cols-1 lg:grid-cols-3 w-full gap-[10px] ">
                                    <div class="flex flex-col justify-center items-center gap-[10px] bg-white p-[20px] rounded-lg w-full h-auto relative overflow-hidden ">
                                        <div class="text-center p-0 sm:p-4">
                                            <img src={onboard1} alt="gain de temps" className="w-12 sm:w-36 mx-auto" />
                                            <h3 class="text-black font-bold text-[22px] leading-[22px]">Répondez à quelques questions</h3>
                                            <p className="m-0">3 minutes suffisent pour créer votre dossier. </p>
                                        </div>
                                    </div>
                                    <div class="flex flex-col justify-center items-center gap-[10px] bg-white p-[20px] rounded-lg w-full h-auto relative overflow-hidden ">
                                        <div class="text-center p-0 sm:p-4">
                                            <img src={onboard3} alt="espace sécurisé" className="w-12 sm:w-36 mx-auto" />
                                            <h3 class="text-black font-bold text-[22px] leading-[22px]">Débutez votre dossier</h3>
                                            <p className="m-0">Ajoutez vos documents en toute sécurité dans votre espace. </p>
                                        </div>
                                    </div>
                                    <div class="flex flex-col justify-center items-center gap-[10px] bg-white p-[20px] rounded-lg w-full h-auto relative overflow-hidden ">
                                        <div class="text-center p-0 sm:p-4">
                                            <img src={onboard2} alt="notaire réactif" className="w-12 sm:w-36 mx-auto" />
                                            <h3 class="text-black font-bold text-[22px] leading-[22px]">Choisissez votre notaire</h3>
                                            <p className="m-0 hidden sm:flex">Choisissez votre notaire, à distance ou près de chez vous.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-10">
                                    <a href="#" className="p-2 text-lg btn-indigo w-fit mx-auto no-underline" onClick={() => { setDebuter(true); }}>Je débute ma démarche chez le notaire</a>
                                    <p className="mt-4">Nos experts sont là pour vous aider, par chat ou au <a href="tel:0176390060" className="text-emerald-600 font-bold">01 76 39 00 60</a> .</p>

                                </div>
                            </>}
                        </div>

                        {debuter === true && <>
                            <div class="w-full h-full">
                                {type === "donation" && (
                                    <WidgetTypeform id="Vtk0ABOY" style={{ height: "100vh" }} />
                                )}
                                {type === "achat" && (
                                    <WidgetTypeform id="JM2MTsDD" style={{ height: "100vh" }} />
                                )}
                                {type === "pma" && (
                                    <div className="p-3 sm:p-10 mt-10 ">
                                        <FormPma onNextStep={onNextStep} />
                                    </div>
                                )}
                                {type === "contrat-mariage" && (
                                    <div className=" p-3 sm:p-10 mt-10 sm:mt-0">
                                        <FormContratMariage />
                                    </div>
                                )}
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Onboard;