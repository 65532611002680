import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Offcanvas,
  ListGroup,
  ListGroupItem,
  OffcanvasHeader,
  OffcanvasBody,
  Alert,
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  TitlePage,
  TitlePageApp,
  TitleSection,
  Panel,
  AlertCta,
} from "../style/Layout";
import { CheckIcon} from '@heroicons/react/20/solid'
import TimelineListItem from "../components/TimelineListItem";
import Articles from "../components/Articles";
import FaqVendeur from "../components/FaqVendeur";
import FaqAcheteur from "../components/FaqAcheteur";
import TimelineChronologie from "../components/TimelineChronologie";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import CardHelp from "../components/CardHelp";
import AideCompromis from "../components/AideCompromis";
import AideActe from "../components/AideActe";
import styled from "styled-components";
import backWelcome from "../img/back-alert-welcome.png";
import photoNotaire from "../img/photo-notaire.png";
import CardNotaireDashboard from '../components/CardNotaireDashboard';

function Dashboard(args) {
  const [canvas, setCanvas] = useState(false);
  const [canvasCompromis, setCanvasCompromis] = useState(false);
  const [canvasActe, setCanvasActe] = useState(false);

  const toggle3 = () => setCanvas(!canvas);
  const toggleCompromis = () => setCanvasCompromis(!canvasCompromis);
  const toggleActe = () => setCanvasActe(!canvasActe);


  const [pdfName, setPdfName] = useState(null);
  const toggleModal = () => setPdfName(null);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  console.log("args dahboasrd", args);


  if (args.info === "newUser") {
    return (
      <>
        <Container>
          <Row>
            <TitlePageApp>
              <Col md="12">
                <TitlePage>
                  Bonjour <span role="img">👋</span>{" "}
                </TitlePage>
                <p>
                  Votre transaction est en attente de validation. Nous vous
                  enverrons un email lorsquelle sera validée.{" "}
                </p>
              </Col>
            </TitlePageApp>
            <Col md="12">
              <TitleSection className="mt-5">
                Suivi de votre transaction
              </TitleSection>
              <Panel>
                <ListGroupActionAmener numbered>
                  <ListGroupItem className="done">
                    <div className="d-flex flex-column">
                      <h4>Création de votre transaction immobilière</h4>
                      <p>Aujourd'hui</p>
                    </div>
                  </ListGroupItem>
                  <ListGroupItem className="encours">
                    <div className="d-flex flex-column">
                      <h4>Vérification de votre transaction immobilière</h4>
                      <p>En cours</p>
                    </div>
                  </ListGroupItem>
                  <ListGroupItem className="avenir">
                    <div className="d-flex flex-column">
                      <h4>
                        Ouvertures des accès aux utilisateurs (acheteur(s),
                        vendeur(s), agent immobilier)
                      </h4>
                      <p>A venir</p>
                    </div>
                  </ListGroupItem>
                  <ListGroupItem className="avenir">
                    <div className="d-flex flex-column">
                      <h4>Prise en charge de l'affaire par votre notaire</h4>
                      <p>A venir</p>
                    </div>
                  </ListGroupItem>
                </ListGroupActionAmener>
              </Panel>
            </Col>
          </Row>
        </Container>
      </>
    );
  } else if (args !== null) {
    return (
      <>
        <Container>
          <Row className="d-flex align-self-start">
            <TitlePageApp>
              <Col md="7">
                <TitlePage>Bonjour {args.user.prenom} 👋</TitlePage>
                <p>
                  Voici l'avancement de votre transaction :{" "}
                  <b>{args.transaction.nom}</b>{" "}
                </p>
              </Col>
            </TitlePageApp>

            <Col md="12" className="mt-3">
            {args.transaction.statut_marketplace_notaire === "notaire trouvé" &&
                <>
                  <p className='mt-1 text-lg text-gray-500'><span role="img">👋</span> Votre dossier a bien été pris en charge par votre notaire.</p>
                  <CardNotaireDashboard nom={args.transaction.nom_from_notaire} prenom={args.transaction.prenom_from_notaire} email={args.transaction.email_from_notaire} telephone={args.transaction.telephone_from_notaire} adresse={args.transaction.adresse_from_notaire} cp={args.transaction.code_postal_from_notaire} ville={args.transaction.ville_from_notaire} photo={args.transaction.photo_profil_from_notaire} />
                </>
              }
              {args.transaction.statut_marketplace_notaire === "en recherche de notaire" &&
                <>
                  <AlertCta color="primary" className="bg-gradient-to-r from-teal-200 to-teal-500">
                    <div className="bloc">
                      <h3>Trouvez le notaire idéal</h3>
                      <ul>
                        <li><CheckIcon className="size-6 mr-2 inline-flex"/> Suivez l'avancement depuis votre espace Clotere</li>
                        <li><CheckIcon className="size-6 mr-2 inline-flex"/>  Transmettez vos documents de manière sécurisée</li>
                        <li><CheckIcon className="size-6 mr-2 inline-flex"/>  Bénéficiez de l'accompagnement de votre conseiller</li>
                      </ul>
                      <Link to="/app/notaires" className="btn-primary no-underline">Trouver mon notaire</Link>
                    </div>

                    <div className="blog-img"><img src={photoNotaire} alt="trouver votre notaire" /></div>

                  </AlertCta>
                </>
              }


              {/**Informations sur l'attribution de l'affaire au notaire */}
              {args.transaction.statut_marketplace_notaire === "vérification avant attribution"  &&
                <>
                  <AlertWelcome>
                    <p><span role="img">👋</span> Nous avons averti(e) votre notaire de votre choix, il va bientôt prendre en charge votre affaire.
                    </p>
                  </AlertWelcome>
                </>
              }
              {args.transaction.statut_marketplace_notaire === "en cours d'attribution"  &&
                <>
                  <AlertWelcome>
                    <p><span role="img">👋</span> Nous avons averti(e) votre notaire de votre choix, il va bientôt prendre en charge votre affaire.
                    </p>
                  </AlertWelcome>
                </>
              }

              {/** action à mener**/}
              {args.action !== undefined && args.action.length > 0 ? (
                <>
                  <TitleSection>Vos actions à mener</TitleSection>
                  <Panel>
                    <ListGroupActionAmener numbered>
                    {args.action
                      .slice() // Crée une copie pour éviter de modifier l'original
                      .sort((a, b) => (a.ordre || 0) - (b.ordre || 0)) // Trier par ordre croissant
                      .map((col, i) => (
                        <>
                          {args.action[i].nom === "Ajout document" ? (
                            <>
                              <TimelineListItem
                                type="document"
                                statut={args.action[i].statut}
                              />
                            </>
                          ) : (
                            <> </>
                          )}
                          {args.action[i].nom ===
                            "Questionnaire de connaissance" ? (
                            <>
                              <TimelineListItem
                                user={args.user}
                                type="questionnaire"
                                statut={args.action[i].statut}
                                action={args.action[i].id}
                              />
                            </>
                          ) : (
                            <> </>
                          )}

                          {args.action[i].nom === "Rib" ? (
                            <>
                              <TimelineListItem
                                type="rib"
                                statut={args.action[i].statut}
                              />
                            </>
                          ) : (
                            <> </>
                          )}
                        </>
                      ))}
                    </ListGroupActionAmener>
                  </Panel>
                </>
              ) : (
                <></>
              )}



              {/** help section **/}
              <TitleSection className="mt-5">Respirez on vous explique tout 🧘 </TitleSection>
              <Row>
                <Col md='8'>
                {args.user.role === "vendeur" &&
                <FaqVendeur/>
                }
                {args.user.role === "acheteur" &&
                <FaqAcheteur/>
                }
                  
                </Col>
                <Col md='4'>
                <TimelineChronologie/>
                </Col>
              </Row>
              
              <TitleSection className="mt-5">Votre notaire en clair 🔥 <br /></TitleSection>
              <Articles />
              <Row><Col md="12" align="center" className="mt-4 mb-4"><a className="btn-main no-underline" color="primary" href="https://clotere.framer.website" target="blank">Tous nos articles</a></Col></Row>



              <CardHelp email={args.user.email} />
            </Col>
          </Row>
        </Container>
        {/** Toutes les modals et canvas */}
        <Offcanvas
          isOpen={canvas}
          toggle={toggle3}
          {...args}
          direction="end"
          scrollable
        >
          <OffcanvasHeader toggle={toggle3}>
            Vous n'êtes plus disponible ?
          </OffcanvasHeader>
          <OffcanvasBody>
            <p>
              Pour indiquer les raisons de votre indisponibilités veuillez
              contacter votre conseillé directement.
            </p>
          </OffcanvasBody>
        </Offcanvas>
        <Offcanvas
          isOpen={canvasCompromis}
          toggle={toggleCompromis}
          {...args}
          direction="end"
          scrollable
        >
          <OffcanvasHeader toggle={toggleCompromis}>
            Qu'est-ce que la promesse de vente ?
          </OffcanvasHeader>
          <AideCompromis />
        </Offcanvas>
        <Offcanvas
          isOpen={canvasActe}
          toggle={toggleActe}
          {...args}
          direction="end"
          scrollable
        >
          <OffcanvasHeader toggle={toggleActe}>
            Qu'est-ce que l'acte authentique de vente ?
          </OffcanvasHeader>
          <AideActe />
        </Offcanvas>

        <Modal isOpen={pdfName != null} toggle={toggleModal} size="lg" centered>
          {pdfName != null ? (
            pdfName.type === "application/pdf" ? (
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={pdfName.url}
                  plugins={[
                    // Register plugins
                    defaultLayoutPluginInstance,
                  ]}
                />
              </Worker>
            ) : (
              <img alt="document" src={pdfName.url}></img>
            )
          ) : (
            <></>
          )}
        </Modal>
      </>
    );
  }
}

const ListGroupActionAmener = styled(ListGroup)`
  border-radius: 0;
  border: 0;
  .list-group-item {
    border: 0;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 30px;
    &:before {
      content: counters(section, "") "";
      counter-increment: section;
      display: inline;
      border: 1px solid #ddd;
      font-weight: 500;
      border-radius: 100px;
      padding: 10px 17px;
      margin-right: 30px;
    }
    &.done {
      &:before {
        content: "✔️";
      }
    }
    h4 {
      color: #1d2b28;
    }
    color: #84847c;
  }
  @media all and (max-width: 768px) {
    .list-group-item {
      flex-direction: column;
      &:before {
        margin-bottom: 1rem;
      }
    }
  }
`;

const AlertWelcome = styled(Alert)`
  background-image: url(${backWelcome});
  background-size: cover;
  background-repeat: no-repeat;
  color: white !important;
  border: 0 !important;
  border-radius: 16px !important;
  margin-bottom: 3rem;
  padding: 3rem !important;
  p {
    margin-bottom: 0;
    font-size: 18px;
  }
  a {
    color: white !important;
  }
`;

const PanelDocVente = styled(Panel)`

.titre{
    align-items:center;
    img{margin-right:8px;}
    h4{font-size:22px}
}
.list{
    margin-top:1rem;
    margin-left:1rem;
    .list-group{
        border:0;
        .list-group-item{
            border:0;
            display:flex;
            flex-direction:row;
            align-items:flex-start;
            img{margin-right:1rem;}
            p{
                span{opacity:.5;display:block;}
            }
            .date{
                background:${(props) => props.theme.colors.linearBackground};
                padding:8px;
                text-align:center;
                font-weight:600;
                border-radius:8px;
            }
        }
    }
}
.tuto{
    .card{
        position:relative;
        padding:16px;
        border:0;
        border-radius:16px;
        background:${(props) => props.theme.colors.linearBackground};
        margin-right:2rem;
        margin-bottom:2rem;
        img{
            position: absolute;
            right: 20px;
            top: -25px;
            width: 50px;
        }
        .card-title{
            font-size:16px;
            color:${(props) => props.theme.colors.mainDark};
            font-weight:600;
        }
        ul{
            font-size:16px;
            color:#636060;
            li::marker {
                color: ${(props) => props.theme.colors.main};
                font-size:2rem;
        }
        @media all and (max-width: 768px) {
            margin-bottom:1.5rem;
        }
    }
    @media all and (max-width: 768px) {
        margin-right:0;
        margin-left:0;
    }
}

`;
export const Message = styled.div`
  padding: 1rem;
  background: ${(props) => props.theme.colors.linearBackground};
  text-align: center;
  border-radius: 6px;
`;

export const RowCandidatureNotaire = styled(Row)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  .card {
    height: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid #ddd !important;
    width: 100%;
    margin-bottom: 1rem;
  }
  @media all and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 1rem;
  }
`;



export default Dashboard;
