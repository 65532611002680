import React from "react";

import { Navbar } from './Navbar';
/** composants **/
import HeaderNew2 from './components/HeaderNew2';
import CommentCaMarche from './components/CommentCaMarche';
import NotaireEnClair from "./blog/NotaireEnClair";
import Faq from './components/Faq';
import BlocCta from './components/BlocCta';
import Footer from './components/Footer';
import { useWindowWidth } from "./style/breakpoints";
import PourquoiClotereNew from "./annuaire/PourquoiClotereNew";
import { Helmet } from "react-helmet";
import Testimonials from "./components/Testimonials";



function Home(args) {
    const screenWidth = useWindowWidth();
    return (
        <>
        <Helmet>
            <title>Clotere.fr simplifie votre passage chez le notaire | Donation, achat vente immobilière, Pacs....etc</title>
            <meta name="robots" content="index,follow"/>
            <meta name="googlebot" content="index,follow"/>
            <meta name='description' content='Clotere.fr vous permet de gérer et simplifier vos démarches chez le notaire en ligne : faire une donation, trouver un notaire pour acheter un bien immobilier, vendre son bien immobilier, trouver un notaire, etc' />
            <meta property="og:url" content="https://clotere.fr"/>
            <meta property="og:site_name" content="Clotere"/>
            <meta property="og:image" content="https://clotere.fr/facebook-share-webp"/>
            <meta property="og:type" content="website"/>
            </Helmet> 
        <div>
            <Navbar user={args.user}  />
            <HeaderNew2/>
            <CommentCaMarche/>
            <PourquoiClotereNew/>
            <Testimonials/>
            <NotaireEnClair  />
            <BlocCta/>
            <Faq/>
            <Footer/>
        </div>
        </>
    );
}

export default Home;