import React from "react";
import { Container } from "../annuaire/Container";
import {  CheckIcon} from '@heroicons/react/20/solid'
import Doc from '../img/depot-document.svg'
import Notaire from '../img/choisir-notaire.svg'
import Acte from '../img/reception-acte.svg'

function CommentCaMarche() {
    return (
        <section className="mt-20 mb-20">
            <Container>
            <div class="container mx-auto px-[15px] h-full">
                <div class="flex flex-col justify-between items-start gap-[10px]">
                    <p class="py-2 px-4 rounded-[20px] text-center bg-slate-100 font-bold m-0">
                        Comment ça marche ?
                    </p>
                    <h3 className="text-black leading-[28px] !text-[30px] lg:leading-[30px] lg:text-[45px] font-bold mt-10 sm:mt-0">A vos côtés à toutes les étapes de votre passage chez le notaire</h3>

                    <div class="grid lg:grid-cols-3 w-full gap-[10px] ">
                        <div class="flex flex-col justify-end items-center sm:items-start gap-[10px] bg-gradient-to-r from-slate-200 to-slate-100 p-[20px] rounded-lg w-full   relative overflow-hidden hover:card-hover">
                            <div class="flex flex-col justify-end items-start gap-[10px] ">
                                <img src={Doc} className="size-36 mx-auto mb-8"  alt="trouver votre notaire"/>
                                
                                <p class="text-[24px] leading-[26.4px] sm:text-[28px] sm:leading-[30.8px] tracking-[-0.5px] font-bold">Constituez votre dossier</p>
                                <ul className="h-full flex flex-col gap-1  justify-start items-start  pl-4">
                                    <li className="flex gap-1  text-lg sm:text-xl text-black/70 font-[450]"><CheckIcon className="size-8 "/> Processus 100% digital</li>
                                    <li className="flex gap-1  text-lg sm:text-xl text-black/70 font-[450]"><CheckIcon className="size-8 "/>Téléchargement sécurisé</li>
                                    <li className="flex gap-1 sm:gap-2 text-lg sm:text-xl text-black/70 font-[450]"><CheckIcon className="size-8 "/> Vérification par nos experts </li>
                                </ul>
                            </div>
                        </div>

                        <div class="flex flex-col justify-end  items-center sm:items-start gap-[10px] bg-gradient-to-r from-slate-200 to-slate-100 p-[20px] rounded-lg w-full   relative overflow-hidden hover:card-hover">
                            <div class="flex flex-col justify-end items-start gap-[10px] ">
                            <img src={Notaire} className="size-36 mx-auto mb-8" alt="trouver votre notaire" />
                                <p class="text-[24px] leading-[26.4px] sm:text-[28px] sm:leading-[30.8px] tracking-[-0.5px] font-bold">Choisissez votre notaire</p>
                                <ul className="h-full flex flex-col gap-1  justify-start items-start pl-4">
                                <li className="flex gap-1 sm:gap-2 text-lg sm:text-xl text-black/70 font-[450]"><CheckIcon className="size-8 "/> Contrôle de votre dossier </li>
                                    <li className="flex gap-1  text-lg sm:text-xl text-black/70 font-[450]"><CheckIcon className="size-8 "/> Réactif et connecté</li>
                                    <li className="flex gap-1  text-lg sm:text-xl text-black/70 font-[450]"><CheckIcon className="size-8 "/>Échanges 100% sécurisés</li>
                                   
                                </ul>
                            </div>
                        </div>

                        <div class="flex flex-col justify-end  items-center sm:items-start gap-[10px] bg-gradient-to-r from-slate-200 to-slate-100 p-[20px] rounded-lg w-full   relative overflow-hidden hover:card-hover">
                            <div class="flex flex-col justify-end items-start gap-[10px] ">
                            <img src={Acte} className="size-36 mx-auto mb-8"  alt="trouver votre notaire"/>
                                <p class="text-[24px] leading-[26.4px] sm:text-[28px] sm:leading-[30.8px] tracking-[-0.5px] font-bold">Obtenez vos actes notariés</p>
                                <ul className="h-full flex flex-col gap-1  justify-start items-start  pl-4">
                                <li className="flex gap-1 sm:gap-2 text-lg sm:text-xl text-black/70 font-[450]"><CheckIcon className="size-8 "/> 100% en conformité</li>
                                    <li className="flex gap-1  text-lg sm:text-xl text-black/70 font-[450]"><CheckIcon className="size-8 "/> Disponible rapidement</li>
                                    <li className="flex gap-1  text-lg sm:text-xl text-black/70 font-[450]"><CheckIcon className="size-8 "/>On vous accompagne même après</li>
                                   
                                </ul>
                            </div>
                        </div>


                       
                    </div>
                </div>
            </div>
            </Container>
        </section>

    );
}

export default CommentCaMarche;
