import React, { createContext, useState} from 'react';
import {
  BrowserRouter as Router, Route, Routes,Navigate
} from "react-router-dom";


//page
import Inscription from './Inscription';
import InscriptionVendeur from './InscriptionVendeur';
import AnnonceVendeur from './AnnonceVendeur';
import Invitation from './ConnexionLink';
import Layout from './app/Layout';
import ChoisirNotaire from './app/ChoisirNotaire';
import Notaires from './app/Notaires';
import Onboard from './app/Onboard';
import OnboardDone from './app/OnboardDone';
import Mentionslegales from './Mentionslegales';
import Cgu from './Cgu';
import DemoNotaire from './DemoNotaire';
import RelireDocument from './app/RelireDocument';
import Inventaire from './app/Inventaire';

//article fiches
import CalculFraisNotaire from './blog/CalculFraisNotaire';
import CompositionFraisNotaire from './blog/CompositionFraisNotaire';
import FichesPratiquesNotaire from './blog/FichesPratiques';
import ArticleRoleNotaire from './blog/ArticleRoleNotaire';
import ArticleDocumentsNotaries from './blog/ArticleDocumentsNotaries';
import ArticleChoisirNotaire from './blog/ArticleChoisirNotaire';
import ArticleDeuxNotaires from './blog/ArticleDeuxNotaires';
import ArticleChecklist from './blog/ArticleChecklistEtapesVente';

//annuaire
import AllNotaires from './annuaire/HomeAnnuaire'
import NotairesRegions from './annuaire/NotaireRegions';
import NotaireVilleParis from './annuaire/notaire-ville/NotaireVilleParis';
import FicheNotaire from './annuaire/FicheNotaire';
import CompetencesNotaire from './blog/CompetencesNotaire';
import ArticleEtapesAchatImmo from './blog/ArticleEtapesAchatImmo';
import NotairesVilleLyon from './annuaire/notaire-ville/NotaireVilleLyon';
import NotairesVilleLille from './annuaire/notaire-ville/NotaireVilleLille';
import NotairesVilleBordeaux from './annuaire/notaire-ville/NotaireVilleBordeaux';
import NotairesVilleRennes from './annuaire/notaire-ville/NotaireVilleRennes';
import NotairesVilleMarseille from './annuaire/notaire-ville/NotaireVilleMarseille';
import NotairesVilleStrasbourg from './annuaire/notaire-ville/NotaireVilleStrasbourg';
import NotairesVilleMontpellier from './annuaire/notaire-ville/NotaireVilleMontpellier';
import NotairesVilleToulouse from './annuaire/notaire-ville/NotaireVilleToulouse';
import NotairesVilleAutres from './annuaire/notaire-ville/NotaireVilleAutres';
import HomeAnnuaire from './annuaire/HomeAnnuaire';
import Home from './Home';
import Test from './components/New';
import ScrollToTop from "./components/ScrollToTop";
import NotFound from "./NotFound";
//lead page
import FaireDonation from './leadPage/FaireDonation';
import Acheter from './leadPage/Acheter';
import Pma from './leadPage/Pma';
import PmaLead from './leadPage/PmaLeadPage';
import Divorce from './leadPage/Divorce';
import Adoption from './leadPage/Adoption';
import Mariage from './leadPage/Mariage';
import Vendeur from './Vendeur';

//site petite annonce
import HomeVente from './vente/HomeVente';
import PublierAnnonce from './vente/PublierAnnonce';
import Annonce from './vente/Annonce';


export const userInfoContext = createContext();

const App = () => {
  const [userInfo, setUserInfo] = useState(null);
  //console.log("path", window.location.pathname );

  function getNavbar() {
    if(window.location.pathname === "/app/" ) {
      return "";
    } else if(window.location.pathname === "/app") {
      return "";
       
    }else if(window.location.pathname === "/app/dashboard") {
      return "";
       
    }else if(window.location.pathname === "/app/bien") {
      return "";
    }else if(window.location.pathname === "/app/interlocuteurs") {
      return "";
    }else if(window.location.pathname === "/app/documents") {
      return "";
    } else if(window.location.pathname === "/app/transactions") {
      return "";
    }
    else if(window.location.pathname === "/app/profil") {
      return "";
    } else {
      return "";
    }
  }

  
  return (
    <>

      <Router>
      <ScrollToTop />
        <userInfoContext.Provider value={{ userInfo, setUserInfo }}>
        
                {getNavbar()}
          
          <Routes>
            <Route index element={<Home />} user={userInfo} />
            <Route path="trouver-votre-notaire" element={<HomeAnnuaire />} user={userInfo} />
            <Route path="inscription/:type" element={<Inscription />} user={userInfo} />
            <Route path="vendeur" element={<Vendeur />} user={userInfo} />
            <Route path="vendeur/onboard" element={<InscriptionVendeur />} user={userInfo} />
            <Route path=":id" element={<AnnonceVendeur />} user={userInfo} />
            <Route path="connexion" element={<Invitation />} user={userInfo} />
            {/** APP */}
            <Route path="app" element={<Layout />} user={userInfo} />
            <Route path="onboard/:type" element={<Onboard />} user={userInfo} />
            <Route path="app/notaires" exact element={<Notaires />} user={userInfo} />
            <Route path="app/dashboard" exact user={userInfo} element={<Layout />}  />
            <Route path="app/bien" exact user={userInfo} element={<Layout />}  />
            <Route path="app/interlocuteurs" exact user={userInfo} element={<Layout />}  />
            <Route path="app/documents" exact user={userInfo} element={<Layout />}  />
            <Route path="app/notaire" exact user={userInfo} element={<Layout />}  />
            <Route path="app/dashboard-donation" exact user={userInfo} element={<Layout />}  />
            <Route path="app/contact-donation" exact user={userInfo} element={<Layout />}  />
            <Route path="app/document-donation" exact user={userInfo} element={<Layout />}  />
            <Route path="app/dashboard-annonce" exact user={userInfo} element={<Layout />}  />
            <Route path="app/dashboard-annonce-notaire" exact user={userInfo} element={<Layout />}  />
            <Route path="app/dashboard-annonce-2" exact user={userInfo} element={<Layout />}  />
            <Route path="app/dashboard-pma" exact user={userInfo} element={<Layout />}  />
            <Route path="app/document-pma" exact user={userInfo} element={<Layout />}  />
            <Route path="app/notaire-pma" exact user={userInfo} element={<Layout />}  />
            <Route path="app/dashboard-mariage" exact user={userInfo} element={<Layout />}  />
            <Route path="app/document-mariage" exact user={userInfo} element={<Layout />}  />
            <Route path="app/profil" exact user={userInfo} element={<Layout />}  />
            <Route path="app/relecture/:id_event" exact user={userInfo} element={<RelireDocument />}  />
            <Route path="app/choisir-notaire/:id/:reco" exact user={userInfo} element={<ChoisirNotaire />}  />
            <Route path="app/inventaire/:id_action" exact user={userInfo} element={<Inventaire />}  />

            <Route path="info/mentions-legales" exact user={userInfo} element={<Mentionslegales />}  />
            <Route path="info/cgu" exact user={userInfo} element={<Cgu />}  />
            <Route path="demo-notaire" exact user={userInfo} element={<DemoNotaire />}  />
            {/** Page de lead  **/}
            <Route path="faire-une-donation" element={<FaireDonation />} user={userInfo} />
            <Route path="/faire-une-donation" element={<FaireDonation />} user={userInfo} />
            <Route path="notaire-acheteur" element={<Acheter />} user={userInfo} />
            <Route path="/notaire-divorce" element={<Divorce />} user={userInfo} />
            <Route path="/notaire-pma" element={<Pma />} user={userInfo} />
            <Route path="/notaire-reconnaissance-conjointe-enfant" element={<Pma />} user={userInfo} />
            <Route path="/trouver-un-notaire-pma" element={<PmaLead />} user={userInfo} />
            <Route path="/notaire-adoption" element={<Adoption />} user={userInfo} />
            <Route path="/notaire-contrat-mariage" element={<Mariage />} user={userInfo} />
            {/** Articles fiche pratique **/}
            <Route path="/fiches-pratiques-notaire/" exact user={userInfo} element={<FichesPratiquesNotaire />}  />
            <Route path="/fiches-pratiques-notaire/role-du-notaire-en-immobilier" exact user={userInfo} element={<ArticleRoleNotaire />}  />
            <Route path="/fiches-pratiques-notaire/un-ou-deux-notaires-immobilier" exact user={userInfo} element={<ArticleDeuxNotaires />}  />
            <Route path="/fiches-pratiques-notaire/document-de-vente-notaire" exact user={userInfo} element={<ArticleDocumentsNotaries />}  />
            <Route path="/achat-immobilier/comment-choisir-son-notaire" exact user={userInfo} element={<ArticleChoisirNotaire />}  />
            <Route path="/achat-immobilier/etapes-achat-immobilier" exact user={userInfo} element={<ArticleEtapesAchatImmo />}  />
            <Route path="/achat-immobilier/calcul-frais-de-notaire" exact user={userInfo} element={<CalculFraisNotaire />}  />
            <Route path="/achat-immobilier/de-quoi-se-compose-les-frais-de-notaire" exact user={userInfo} element={<CompositionFraisNotaire />}  />
            <Route path="/notaires/domaines-competences-notaire" exact user={userInfo} element={<CompetencesNotaire />}  />
            <Route path="/ressources/vendre-seul-son-bien-checklist-etapes-de-vente" exact user={userInfo} element={<ArticleChecklist />}  />
            {/** Annuaires **/}
            <Route path="/fr/notaires"  user={userInfo} element={<AllNotaires />}  />
            <Route path="/trouvez-un-notaire"  user={userInfo} element={<AllNotaires />}  />
            <Route path="/notaires"  user={userInfo} element={<AllNotaires />}  />
            <Route path="/fr/notaires/region/ile-de-france"  user={userInfo} element={<NotairesRegions />}  />
            <Route path="/fr/notaires/ville/paris"  user={userInfo} element={<NotaireVilleParis />}  />
            <Route path="/fr/notaires/ville/lyon"  user={userInfo} element={<NotairesVilleLyon />}  />
            <Route path="/fr/notaires/ville/lille"  user={userInfo} element={<NotairesVilleLille />}  />
            <Route path="/fr/notaires/ville/bordeaux"  user={userInfo} element={<NotairesVilleBordeaux />}  />
            <Route path="/fr/notaires/ville/montpellier"  user={userInfo} element={<NotairesVilleMontpellier />}  />
            <Route path="/fr/notaires/ville/rennes"  user={userInfo} element={<NotairesVilleRennes />}  />
            <Route path="/fr/notaires/ville/toulouse"  user={userInfo} element={<NotairesVilleToulouse />}  />
            <Route path="/fr/notaires/ville/marseille"  user={userInfo} element={<NotairesVilleMarseille />}  />
            <Route path="/fr/notaires/ville/strasbourg"  user={userInfo} element={<NotairesVilleStrasbourg />}  />
            {/** autres url */}
            <Route path="/notaires-a-paris"  user={userInfo} element={<NotaireVilleParis />}  />
            <Route path="/notaires-a-lyon"  user={userInfo} element={<NotairesVilleLyon />}  />
            <Route path="/notaires-a-lille"  user={userInfo} element={<NotairesVilleLille />}  />
            <Route path="/notaires-a-bordeaux"  user={userInfo} element={<NotairesVilleBordeaux />}  />
            <Route path="/notaires-a-montpellier"  user={userInfo} element={<NotairesVilleMontpellier />}  />
            <Route path="/notaires-a-rennes"  user={userInfo} element={<NotairesVilleRennes />}  />
            <Route path="/notaires-a-toulouse"  user={userInfo} element={<NotairesVilleToulouse />}  />
            <Route path="/notaires-a-marseille"  user={userInfo} element={<NotairesVilleMarseille />}  />
            <Route path="/notaires-a-strasbourg"  user={userInfo} element={<NotairesVilleStrasbourg />}  />
            <Route path="/fr/notaires/ville/:nameCity"  user={userInfo} element={<NotairesVilleAutres/>}  />
            <Route path="/notaire/:nom"  user={userInfo} element={<FicheNotaire />}  />

            {/** site petite annonce*/}
            <Route path="/vendre" exact user={userInfo} element={<HomeVente />}  />
            <Route path="/publier-annonce" exact user={userInfo} element={<PublierAnnonce />}  />
            <Route path="/annonce" exact user={userInfo} element={<Annonce />}  />
            <Route path="/annonce-immobilier-particulier/:region/:id" exact user={userInfo} element={<Annonce />}  />

            <Route path="/test" exact user={userInfo} element={<Test />}  />
            <Route path="/404" element={<NotFound/>} />
            <Route path="*" element={<Navigate replace to="/404" />} />

            {/** after paiement onboard */}
            <Route path="/onboard/done" exact user={userInfo} element={<OnboardDone />}  />


          </Routes>
        </userInfoContext.Provider>
      </Router>
    </>
  );
};

export default App;
