import React, { useState } from "react";
import {
  Container,
  Row,
  Col,Offcanvas,OffcanvasHeader
} from "reactstrap";
import {
  TitlePageBig,
  TitlePageApp,
} from "../../style/Layout";
import { Link } from "react-router-dom";
import { DocumentCheckIcon, FaceSmileIcon, LifebuoyIcon,InformationCircleIcon,CalendarDaysIcon,DocumentCurrencyEuroIcon,ArrowUpRightIcon} from "@heroicons/react/24/solid";
import AlertComponent from "../../components/Alert";
import CardNotaireDashboard from '../../components/CardNotaireDashboard';
import icnDocVente from "../../img/icn-doc-vente.svg";
import { PopupButton } from '@typeform/embed-react';
import AideRca from "../../components/AideRca";
import ActePmaContenu from "../../components/ActePmaContenu";


const items = [
  {
    name: 'Gagnez du temps, signez plus vite !',
    description: 'Constituez votre dossier facilement en suivant nos instructions simples.',
    iconColor: 'bg-pink-500',
    icon: DocumentCheckIcon,
  },
  {
    name: 'Bénéficiez d\'un suivi premium',
    description: 'Votre conseiller Clotere réponds à toutes vos questions juridiques tout au long de votre démarche. Disponible 24/24 par chat ou whatsapp',
    iconColor: 'bg-purple-500',
    icon: LifebuoyIcon,
  },
  {
    name: 'Suivez simplement l\'avancement de votre dossier',
    description: 'Un tableau de bord unique pour suivre l\'avancement de votre dossier. ',
    iconColor: 'bg-yellow-500',
    icon: FaceSmileIcon,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


function Notaire(args) {
  const [canvas, setCanvas] = useState(false);
  const toggleRCA = () => setCanvas(!canvas);
  return (
    <>
      <Container>
        <Row className="d-flex align-self-start">
          <TitlePageApp>
            <Col md="7"><TitlePageBig className="mb-4">Votre notaire</TitlePageBig></Col>
          </TitlePageApp>

          
            {/** si en recherche de notaire */}
            {args.pma.statut_marketplace_notaire === "en recherche de notaire" &&
              <Col md="12" className="mt-3">
              <div className="mx-auto max-w-lg">
                <div>
                  <div className="text-center">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                      className="mx-auto size-12 text-gray-400"
                    >
                      <path
                        d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <h2 className="mt-2 text-lg font-bold text-gray-900">Confiez gratuitement votre affaire à un notaire Clotere</h2>
                  </div>
                  <ul role="list" className="mt-6 divide-y divide-gray-200 border-b border-t border-gray-200">
                    {items.map((item, itemIdx) => (
                      <li>
                        <div className="group relative flex items-start space-x-3 py-4">
                          <div className="shrink-0">
                            <span
                              className={classNames(item.iconColor, 'inline-flex size-10 items-center justify-center rounded-lg')}
                            >
                              <item.icon aria-hidden="true" className="size-6 text-white" />
                            </span>
                          </div>
                          <div className="min-w-0 flex-1">
                            <div className="text-lg font-bold text-gray-900">
                              <a href={item.href}>
                                <span aria-hidden="true" className="absolute inset-0" />
                                {item.name}
                              </a>
                            </div>
                            <p className="text-sm text-gray-500">{item.description}</p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
  
                  <Link to="/app/notaires" className="btn-main no-underline mx-auto">Choisir mon notaire</Link>
  
                </div>
              </div>
            </Col>
            }
             {/**Informations sur l'attribution de l'affaire au notaire */}
             {args.pma.statut_marketplace_notaire === "vérification avant attribution"  &&
                <AlertComponent type="info" message="👋 Nous avons averti(e) votre notaire de votre choix, il va bientôt prendre en charge votre affaire."/>
              }
              {args.pma.statut_marketplace_notaire === "en cours d'attribution"  &&
               <AlertComponent type="info" message="👋 Nous avons averti(e) votre notaire de votre choix, il va bientôt prendre en charge votre affaire."/>
              }
              {args.pma.statut_marketplace_notaire === "notaire trouvé" &&
                <>
                  <CardNotaireDashboard nom={args.pma.nom_from_notaire} prenom={args.pma.prenom_from_notaire} email={args.pma.email_from_notaire} telephone={args.pma.telephone_from_notaire} adresse={args.pma.adresse_from_notaire} cp={args.pma.code_postal_from_notaire} ville={args.pma.ville_from_notaire} photo={args.pma.photo_profil_from_notaire} />
                </>
              }
              {/**composant document de vente (ne s'affiche que si statut notaire trouvé) */}
              {args.pma.statut_marketplace_notaire === "notaire trouvé" &&
                <>
                  {args.evenement !== undefined && args.evenement.length > 0 ? (
                    <>
                      <p className="">
                        Vos actes notariés
                      </p>

                        {args.evenement.map((col, i) => (
                          <>
                          <div className="flex flex-col gap-4 flex-wrap">
                            <div className="sm:w-1/2 w-full">
                              <div className="">
                                <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
                                  <dl className="flex flex-wrap">
                                    <div className="flex-auto pl-6 pt-6">
                                      <img
                                        src={icnDocVente}
                                        alt="document-vente"
                                        className="mb-2 h-16"
                                      />
                                      <dd className="mt-1 text-xl font-semibold leading-6 text-gray-900">
                                        {args.evenement[i].type === "reconnaissance conjointe anticipée" && <>Reconnaissance conjointe anticipée</>}

                                      </dd>
                                    </div>
                                    <div className="flex-none self-end px-6 pt-4">
                                      {args.evenement[i].etat === "pas fait" && <><dd className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20">Non débuté</dd></>}
                                      {args.evenement[i].etat === "en cours" && <><dd className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">En cours</dd></>}
                                      {args.evenement[i].etat === "erreurs signalées" && <><dd className="inline-flex items-center rounded-md bg-orange-50 px-2 py-1 text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-600/20">Erreurs signalées</dd></>}
                                      {args.evenement[i].etat === "fait" && <><dd className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">Rédigé</dd></>}


                                    </div>
                                    <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                                      <dt className="flex-none">
                                        <span className="sr-only">Client</span>
                                        <InformationCircleIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                                      </dt>

                                      {args.evenement[i].etat === "pas fait" &&
                                        <>
                                          <div className="flex flex-column">
                                            <dd className="text-sm font-medium leading-6 text-gray-500">Non débuté</dd>
                                          </div>
                                        </>}

                                      {args.evenement[i].etat === "fait" &&
                                        <>
                                          <div className="flex flex-column">
                                            <dd className="text-sm font-medium leading-6 text-gray-900">Votre document est prêt 🥳 !</dd>
                                            <a href={`/app/relecture/${args.evenement[i].id}`} className="btn-main no-underline w-content">Relire le document</a>
                                          </div>
                                        </>}
                                      {args.evenement[i].etat === "erreurs signalées" &&
                                        <>
                                          <div className="flex flex-column">
                                            <dd className="text-sm font-medium leading-6 text-gray-900">Des erreurs ont été signalées sur ce document.</dd>
                                            <a href={`/app/relecture/${args.evenement[i].id}`} className="btn-main no-underline w-content">Relire le document</a>
                                          </div>
                                        </>}
                                    </div>
                                    <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                                      <dt className="flex-none">
                                        <CalendarDaysIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                                      </dt>
                                      {args.evenement[i].statut_from_rdv[0] === "a programmer" && <dd className="text-sm leading-6 text-gray-500">Aucun rendez-vous prévu pour le moment</dd>}
                                      {args.evenement[i].statut_from_rdv[0] === "en cours de prog" &&
                                        <div className="flex flex-column">
                                          <dd className="text-sm leading-6 text-gray-900"> En cours de programmation</dd>
                                          <PopupButton
                                            id={args.evenement[i].lien_typeform_reservtion[0]}
                                            size={100}
                                            hidden={{ id_user: args.user.airtable_id, nom_transaction: args.pma.id }}
                                            autoClose={false}
                                            style={{ border: '0', background: 'none' }}
                                          >
                                            <button className="btn-main" >Indiquez vos disponibilités</button>
                                          </PopupButton>
                                        </div>
                                      }
                                      {args.evenement[i].statut_from_rdv[0] === "programme" &&
                                        <>
                                          <div className="flex flex-column">
                                            <dd className="text-sm leading-6 text-gray-900"> Rendez-vous prévu le :</dd>
                                            <dd className="inline-flex items-center rounded-md bg-emerald-50 px-2 py-1 text-md font-bold text-emerald-700 ring-1 ring-inset ring-emerald-600/20">{args.evenement[i].date_from_rdv[0]}</dd>
                                          </div>
                                        </>
                                      }


                                      {args.evenement[i].etat === "information(s) manquante(s)" ? (<>Des informations sont manquantes</>) : (<>{" "}</>)}
                                      {args.evenement[i].etat === "a signer" ? (<>Votre document est prêt à être signé !</>) : (<>{" "}</>)}

                                    </div>
                                  </dl>
                                  <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
                                  {args.evenement[i].type === "reconnaissance conjointe anticipée" && 
                                  <>
                                  <a className="text-sm font-semibold leading-6 text-gray-900" onClick={toggleRCA}>Tout comprendre sur la Reconnaissance conjointe anticipée<span aria-hidden="true">&rarr;</span></a>
                                  </>}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-full sm:w-1/2">
                            <ActePmaContenu />
                            </div>
                            </div>

                          </>
                        ))}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              }
 

          

        </Row>
      </Container>

      {/** modal */}
      <Offcanvas
          isOpen={canvas}
          toggle={toggleRCA}
          {...args}
          direction="end"
          scrollable
        >
          <OffcanvasHeader toggle={toggleRCA}>
            Qu'est-ce que la reconnaissance conjointe anticipée ?
          </OffcanvasHeader>
          <AideRca />
        </Offcanvas>
    </>
  );

}
export default Notaire;
