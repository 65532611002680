import React from "react";
import { ChatBubbleLeftEllipsisIcon } from "@heroicons/react/16/solid";
import BoutonReservationRdvConseiller from "./BoutonReservationRdvConseiller";

//feature whatsapp
const phoneNumber = "33753585540";
const openWhatsApp = () => {
    window.open(`https://wa.me/${phoneNumber}`, "_blank");
};


function CardHelp(args) {
    return (
        <>
            <div className="bg-gradient-to-r from-teal-400 to-teal-300 rounded-xl mt-6">
                <div className="mx-auto max-w-7xl px-6 py-12 sm:py-12 lg:px-8">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Une question ?
                    </h2>
                    <p className="text-md leading-8 text-gray-600">
                        Votre conseiller est disponible pour répondre à toutes vos questions par chat, alors n'hésitez pas à lui écrire.
                    </p>
                    <div className=" flex items-center gap-x-6">
                        <button
                            onClick={openWhatsApp}
                            className="p-2 flex items-center  bg-green-500 text-white rounded-2xl hover:bg-green-600 transition text-sm font-bold"
                        >
                            <ChatBubbleLeftEllipsisIcon className="h-4 w-4 mr-2" />
                            Contacter sur Whatsapp
                        </button>
                        <BoutonReservationRdvConseiller/>
                    </div>
                </div>
            </div>
        </>

    );
}

export default CardHelp;