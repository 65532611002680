import React from "react";
import styled from "styled-components";
import { CalendarDaysIcon, DocumentCheckIcon, ExclamationTriangleIcon, InformationCircleIcon } from "@heroicons/react/20/solid";
import TimelineChronologie from "./TimelineChronologie";


export const AideContent = styled.div`

svg.iconflex{
    margin-right:2rem;
    margin-left:2rem;
    color:${props => props.theme.colors.main};
}
.alert{
    background-color: #1af46c2b !important;
    border: 0;
}
li::marker {
    color: ${props => props.theme.colors.main};
}
`;



function AideRca() {
    return (
        <AideContent>
            <p>
                <div className="">
                    Egalement appelé compromis de vente, c'est <b>un pré contrat permettant de formaliser les accords entre le(s) vendeur(s) et l'acheteur(s)</b>.
                </div>

                <p className="text-base font-bold leading-7 text-gray-900 mt-3">3 choses à retenir :</p>
                <div className="rounded-md bg-gray-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <InformationCircleIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                        </div>
                        <div className="ml-3">
                            <dt className="text-base font-bold leading-7 text-gray-900">Ce document définit <span className="text-indigo-800">toutes les modalités de la transaction</span>.</dt>
                            <p className="mt-2 text-base leading-7 text-gray-600 mb-0">C'est dans ce document que l'on retrouvera les modalités de financement, les éventuels travaux à réaliser avant la vente...etc. N'hésitez pas à parler avec votre notaire pour y faire figurer tout ce que vous souhaitez.</p>
                        </div>
                    </div>
                </div>
                <div className="mt-3 rounded-md bg-gray-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <InformationCircleIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                        </div>
                        <div className="ml-3">
                            <dt className="text-base font-bold leading-7 text-gray-900">L'acheteur verse une <span className="text-indigo-800">indemnité d'immobilisation</span>.</dt>
                            <p className="mt-2 text-base leading-7 text-gray-600 mb-0">C'est à la signature de ce document que l'acheteur verse une indemnité d’immobilisation du bien, qui correspond normalement à <b>10 % du montant total de la vente</b>.</p>
                        </div>
                    </div>
                </div>
                <div className="mt-3 rounded-md bg-gray-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <InformationCircleIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                        </div>
                        <div className="ml-3">
                            <dt className="text-base font-bold leading-7 text-gray-900">L'acheteur dispose de <span className="text-indigo-800">10J pour se rétracter</span></dt>
                            <p className="mt-2 text-base leading-7 text-gray-600 mb-0">L'acquéreur dispose d'un délai de 10 jours à compter de la date de signature de la promesse de vente. Passé ce délai, si il veut se rétracter il perd son indeminité d'immobilisation</p>
                        </div>
                    </div>
                </div>

                <p className="text-base font-semibold leading-7 text-gray-900 mt-2 mb-0 flex gap-2 items-center"><CalendarDaysIcon aria-hidden="true" className="h-5 w-5 text-indigo-500" />Quand signe-t-on ce document ?</p>
                <p className="text-base leading-7 text-gray-600">Ce document est signé chez le notaire généralement <b>15 jours après la signature de l'offre d'achat</b> <small>(sous réserve de complétude du dossier)</small></p>

                <TimelineChronologie />


                <p className="text-base font-semibold leading-7 text-gray-900 mb-0 flex gap-2 items-center"><InformationCircleIcon aria-hidden="true" className="h-5 w-5 text-indigo-500" /><b>Comment exercer son droit de rétraction et quelles conséquences ?</b></p>
                <p className="text-base leading-7 text-gray-600">
                    Pendant 10 jours l'acquéreur peut se désister :
                    <div className="mt-6 border-t border-gray-100">
                        <dl className="divide-y divide-gray-100">
                            <div className="p-2">
                                <dd className="leading-6 text-gray-700">• <b>Si le désistement à lieu avant ce délai de rétractation </b> => la somme versée au titre de l'indemnité d'immobilisation lui sera remboursée (délai d’un mois)</dd>
                            </div>
                        </dl>
                    </div>
                    <div className="mt-6 border-t border-gray-100">
                        <dl className="divide-y divide-gray-100">
                            <div className="p-2">
                                <dd className="leading-6 text-gray-700">• <b>Si le désistement à lieu après ce délai de rétractation </b> => il perd son indemnité d’immobilisation qui reste acquise au vendeur.</dd>
                            </div>
                        </dl>
                    </div>
                    <div className="mt-2 border-t border-gray-100">
                        <dl className="divide-y divide-gray-100">
                            <div className="p-2">
                                <dd className="leading-6 text-gray-700">• <b>Si le désistement est dû à la réalisation de l’une des clauses suspensives de la promesse de vente </b>(impossibilité de trouver un prêt immobilier par exemple) => l'acquéreur peut se désengager tout en étant remboursé de son indemnité d’immobilisation.</dd>
                            </div>
                        </dl>
                    </div>

                    Le vendeur reste libre d’annuler la transaction tant que l’acheteur n’a pas levé l’option... mais cela peut lui coûter cher ! En plus de perdre l’indemnité d’immobilisation, il risque d'être condamné à payer des dommages et intérêts pouvant s'élever à 20 % du prix du bien ! Si le vendeur souhaite se rétracter après la levée d’option d'achat, l’acquéreur peut choisir d'être indemnisé comme précédemment expliqué ou alors procéder à une vente forcée du bien immobilier en saisissant le tribunal de grande instance.
                </p>
                <p className="text-base font-semibold leading-7 text-gray-900 mt-4 mb-0 flex gap-2 items-center"><DocumentCheckIcon aria-hidden="true" className="h-5 w-5 text-indigo-500" />Quelles informations doit contenir la promesse de vente ? </p>
                <p className="text-base leading-7 text-gray-600">
                    La rédaction de cet avant-contrat, ne s’improvise pas. Ce contrat doit contenir certaines mentions légales pour être valable :
                </p>

                <ul>
                    <li>• Une <b>description du bien</b> : son emplacement, une description détaillée du type de logement, nombre de pièces, nombre de chambres, surface, espaces extérieurs, dépendances, annexes, etc.</li>
                    <li>• Les <b>identités des parties</b> (vendeur(s) et acheteur(s)).</li>
                    <li>• Le <b>prix de vente</b> du bien.</li>
                    <li>• Les <b>diagnostics immobiliers obligatoires</b>, leur date d’expiration et/ou d’exécution, etc.</li>
                    <li>• Les <b>honoraires de l’agent</b> immobilier.</li>
                    <li>• La mention concernant <b>le délai de rétractation légal de 10 jours</b>.</li>
                    <li>• Les <b>conditions suspensives</b>.</li>
                </ul>

                <p>
                    <p className="text-base font-semibold leading-7 text-gray-900 mt-4 mb-0 flex gap-2 items-center"><ExclamationTriangleIcon aria-hidden="true" className="h-5 w-5 text-indigo-500" />Qu'est-ce que les conditions suspensives ? </p>
                    <p className="text-base leading-7 text-gray-600">
                        Ce sont des conditions spécifiques qui doivent être remplies pour que la vente soit définitivement conclue.
                        <ul>
                            <li>• <b>Tant que la condition n'est pas remplie, la vente est en suspens</b></li>
                            <li>• <b>Si la condition suspensive n'est pas réalisée dans le délai imparti, le compromis de vente peut être annulé</b></li>
                        </ul>
                        La clause suspensive la plus courante est celle concernant l'obtention d'un prêt bancaire, mais il en existe d'autres => <a href="https://clotere.framer.website/blog/les-clauses-suspensives-qu-est-ce-que-c-est" target="blank">Découvrir toutes les clauses suspensives</a></p>

                </p>

                <div className="rounded-md bg-emerald-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            👋
                        </div>
                        <div className="ml-3 flex-1 md:flex md:justify-between">
                            <p className="text-sm text-emerald-700 mb-0">Il vous manque une information ? N'hésitez pas à contacter votre conseiller Clotere.</p>

                        </div>
                    </div>
                </div>




            </p>
        </AideContent>
    );
}

export default AideRca;